import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { showModal, showMessage, clearState } from "../../store/actions/creators/ui.action";
import { postAction } from "../../store/actions/creators/request.action";
import { WITHDRAW, TRANSFER_FEE } from "../../store/actions/action_types/action.types";
import { externalWithdrawURL, intraWithdrawURL, transferFeeURL } from "../../utils/endpoints";
import { currencyFormatter } from "../../utils/helper";
import { AiOutlineBank } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { PinInput } from "../../components/Widget/Form/FormComponent";

const WithdrawalConfirm = ({ token, loading, formData, is_gdl_account, session_token, verify_data, bvn, ...props }) => {
  const { amount, account_number, narration, bank_code } = formData;
  const from_account_number = props.user_wallet[0]?.wallets[0]?.nuban_account;

  const sender_name = `${props.user_info?.first_name} ${props.user_info?.last_name}`.toUpperCase(); // account name

  const txn_gdl_acct_data = { from_account_number, to_account_number: account_number }; // data needed to transfer to gdl accounts
  const txn_other_acct_data = {
    sender_name,
    receiver_name: verify_data?.Name,
    receiver_account_number: account_number,
    sender_account_number: from_account_number,
    receiver_KYC: props.user_info?.kyc_level.level_number,
    receiver_BVN: bvn,
    receiver_bank_code: bank_code?.value,
    NIPSessionID: verify_data?.SessionID,
    recipient_institution_name: bank_code?.label,
  }; // data needed to transfer to other account numbers

  const { control, formState, register, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      ...(is_gdl_account ? txn_gdl_acct_data : txn_other_acct_data), // merge either transfer to gdl account data or other bank data
      user_id: props.user_info.id,
      session_token,
      amount,
      wallet_ref: props.user_info?.user_txn_ref,
      channel_id: "WEB",
      user_ip: "10.10.10.123", // change this later
      transaction_location: "location",
      narration: narration || `${props.user_info?.user_txn_ref} transfer narration`,
    },
  });

  useEffect(() => {
    if (props.withdraw_error != null) {
      props.showMessage("OPEN_MSG_MODAL", { message: props.withdraw_error });
    }
  }, [props.withdraw_error]);

  const onSubmit = data => {
    data.txn_pin = Number(data.txn_pin);
    const url = is_gdl_account ? intraWithdrawURL : externalWithdrawURL;
    props.postAction(WITHDRAW, url, data, token);
  };

  return (
    <div className="px-[50px] pt-[30px] pb-10">
      <form className="flex flex-col items-start gap-[40px] self-stretch" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex py-[17px] px-5 flex-col items-start gap-[10px] self-stretch rounded-xl border border-gray04">
          <div className="flex flex-col gap-[5px] self-stretch">
            <div className="py-5 flex flex-col justify-between items-start gap-[10px] border-b border-gray04">
              <span className="text-gray01 capitalize">Amount</span>
              <span className="text-primary font-medium">{currencyFormatter(amount)}</span>
            </div>
            <div className="py-5 flex flex-col justify-between items-start gap-[10px] border-b border-gray04">
              <span className="text-gray01 capitalize">To</span>
              <span className="text-primary font-medium">{is_gdl_account ? bank_code : bank_code?.label}</span>
              <span className="text-gray01">{verify_data?.account_name ?? verify_data?.Name}</span>
            </div>
            <div className="py-5 flex justify-between items-start gap-[10px]">
              <div className="flex flex-col items-start gap-[10px]">
                <span className="capitalize">narration</span>
                <span className="capitalize text-primary font-medium">{narration || "N/A"}</span>
              </div>
              <div className="flex flex-col items-end gap-[10px]">
                <span className="capitalize">Transaction Fee</span>
                <span className="text-primary font-medium">{currencyFormatter(props.fee?.txn_fee ?? 0)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-[10px] w-full">
          <PinInput
            label="Transaction PIN"
            control={control}
            width="58px"
            error={formState.errors.txn_pin}
            {...register("txn_pin", { required: true, minLength: 4 })}
            className="text-center !self-center"
          />
        </div>
        <div className="flex justify-end items-center gap-7 mt-[20px] self-stretch">
          <AppButton name="Send Money" loading={loading} className="sm:w-[162px]" disabled={!formState.isDirty || !formState.isValid} />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  withdraw_success: state.account.withdraw.success,
  loading: state.account.withdraw.loading,
  withdraw_error: state.account.withdraw.error_msg,
  confirm_data: state.ui.modal.data,
  verify_data: state.withdrawal.verify_account.data,
  session_token: state.auth.login.login_token,
  bvn: state.account.get_wallets.bvn,
  fee: state.withdrawal.fee.data,
});

export default connect(mapStateToProps, { showModal, postAction, showMessage, clearState })(WithdrawalConfirm);
