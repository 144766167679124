import React, { useState, Fragment, useEffect, useCallback, useRef, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Fieldset, PassType, ToggleContainer } from "./form.style";
import { Listbox, Transition, RadioGroup, Combobox, Switch } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import OtpInput from "react-otp-input";
import { currencyFormat, currencyFormatter, getBase64 } from "../../../utils/helper";
import "react-phone-input-2/lib/style.css";
import CurrencyInput from "react-currency-input-field";
import Datepicker from "react-tailwindcss-datepicker";
import { ArrowDown2, Calendar, Calendar2, Camera, Eye, EyeSlash, Record, RecordCircle, Stop, TickSquare } from "iconsax-react";
import { useMediaQuery } from "@mui/material";
import clsx from "clsx";
import { CustomCaption } from "../../Common/common";
import { FlatList } from "../List/List";
import { countryCodeAndFlag } from "../../../utils/country_flag_and_calling_code";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export const TextField = React.forwardRef(({ label, type, name, control, onChange, className, error, placeholder, height, disabled, value, prefix, defaultValue, rightProps, ...props }, ref) => {
  return (
    <Fieldset className={`flex flex-col gap-[10px] flex-1 text-left self-stretch`}>
      <legend className="2xl:text-base xl:text-base lg:text-sm text-xs text-gray01">{label}</legend>
      <div
        className={`px-5 py-[10px] rounded-lg flex border placeholder:text-sm ${error ? " border-red-500" : " border-[#E4E4E7]"} ${
          disabled ? "bg-gray-50 text-gray-400" : "bg-white text-primary"
        } ${className}`}
      >
        {type === "textarea" && <textarea name={name} id={name} onChange={onChange} ref={ref} style={{ minHeight: height }} />}
        {["email", "text", "number", "file", "currency"].includes(type) &&
          (type === "number" ? (
            <Controller
              name={name}
              render={({ field: { onChange } }) => <input type={"text"} placeholder={placeholder} onChange={e => onChange(Number(e.target.value, 10))} ref={ref} disabled={disabled} />}
              control={control}
            />
          ) : type === "currency" ? (
            <Controller
              name={name}
              control={control}
              defaultValue={value || null}
              render={({ field: { onChange, value } }) => (
                <CurrencyInput
                  ref={ref}
                  disabled={disabled}
                  placeholder={placeholder}
                  value={value}
                  decimalsLimit={2}
                  prefix={prefix || "\u20A6"}
                  onValueChange={(value, name) => onChange(value ? Number(value) : "")}
                />
              )}
            />
          ) : (
            <input type={type} name={name} placeholder={placeholder} onChange={onChange} disabled={disabled} className={className} defaultValue={defaultValue} ref={ref} value={value} />
          ))}
        {rightProps && rightProps}
      </div>
      {props.caption && <div>{props.caption}</div>}
      {error && <CustomCaption message={error.message} />}
    </Fieldset>
  );
});

export const PasswordField = React.forwardRef(({ label, name, defaultValue, onChange, className, error, placeholder, ...props }, ref) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fieldset className="flex flex-col gap-[10px] flex-1 text-left self-stretch">
      <legend className="2xl:text-base xl:text-base lg:text-sm text-xs">{label}</legend>
      <PassType className={`flex relative w-full rounded-lg py-[10px] px-5 border text-primary ${error ? "border-red-500" : "border-[#E4E4E7]"}`}>
        <input type={visible ? "text" : "password"} name={name} placeholder={placeholder} onChange={onChange} ref={ref} className="placeholder:font-grotesk-font" />
        <span className="p_visible shrink-0" onClick={() => setVisible(!visible)}>
          {visible ? <Eye size={20} /> : <EyeSlash size={20} />}
        </span>
      </PassType>
      {props.caption && <div>{props.caption}</div>}
    </Fieldset>
  );
});

export const SelectField = React.forwardRef(({ label, name, item, error, selected_item, value, control, defaultValue, className, disabled, onPress, ...props }, ref) => {
  const [selected, setSelected] = useState(defaultValue || "Select One");

  const handleChange = onChange => e => {
    onChange(e[value] || e);
    setSelected(e[selected_item] || e);
  };

  return (
    <Fieldset className={`flex flex-col gap-[10px] flex-1 text-left self-stretch`}>
      <legend className="text-base">{label}</legend>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field: { onChange, value } }) => (
          <>
            <Listbox value={value} onChange={handleChange(onChange)}>
              <div className="relative" onClick={onPress}>
                <div
                  className={`px-5 py-[12px] rounded-lg flex placeholder:text-sm outline-none ${error ? "border border-red-500" : "border border-[#E4E4E7]"} ${
                    disabled ? "bg-gray-50 text-gray-400" : "bg-white text-primary"
                  } ${className}`}
                >
                  <Listbox.Button className={`relative w-full flex text-right ${disabled && !className ? "cursor-not-allowed" : "cursor-pointer"} text-sm outline-none`}>
                    <span className="block truncate">{disabled ? defaultValue : (selected[selected_item] || selected).toString().replace(/_/g, " ")}</span>
                    <span className="absolute inset-y-0 right-0 flex items-start pr-0 pointer-events-none">
                      <ArrowDown2 size={22} color="#1F2734" />
                    </span>
                  </Listbox.Button>
                </div>
                {!disabled && (
                  <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {item?.map((content, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) => `cursor-default select-none relative py-2 pl-3 pr-9 outline-none hover:text-white ${active ? "text-white bg-gray-400" : "text-gray-900"}`}
                          value={content}
                        >
                          {({ selected, active }) => (
                            <div className="flex justify-between self-stretch">
                              <div className="flex items-center">
                                <span className={`block truncate ${selected ? "font-semibold" : "font-normal"}`}>{(content[selected_item] || content).toString().replace(/_/g, " ")}</span>
                              </div>

                              {selected ? (
                                <div className={`${active ? "text-white" : "text-red-800"} absolute inset-y-0 right-0 flex items-center pr-4`}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </div>
                              ) : null}
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                )}
              </div>
            </Listbox>
          </>
        )}
      />
      {props.caption && <div>{props.caption}</div>}
    </Fieldset>
  );
});

export const ToggleField = React.forwardRef(({ label, name, onPress, onChange, className, error, control }, ref) => {
  const [checked, setChecked] = useState(false);
  const handleChange = e => {
    setChecked(!checked);
  };
  return (
    <ToggleContainer className={clsx(`flex justify-between items-center`, className)}>
      <label for="toggle" className="text-sm text-left" onClick={onPress}>
        {label}
      </label>
      <div className={clsx(`relative items-center flex md:w-[53px] md:h-[32px] w-6 ml-2 align-middle select-none transition duration-200 ease-in`)}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => (
            <Switch
              ref={ref}
              checked={checked}
              onChange={e => {
                onChange(e);
                setChecked(!checked);
              }}
              className={`${
                checked ? "bg-brand01" : "bg-[#BABABA]"
              } relative inline-flex h-[26px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${checked ? "translate-x-[24px]" : "translate-x-[0.3px]"}
            pointer-events-none inline-block h-[22px] w-[22px] my-auto transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          )}
        />
      </div>
    </ToggleContainer>
  );
});

export const Checkbox = React.forwardRef(({ label, name, control, className }, ref) => {
  const [checked, setChecked] = useState({});

  const handleClick = () => {
    setChecked({ ...checked, [name]: !checked[name] });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, ...props }) => (
        <div className="flex self-stretch">
          <label htmlFor={`toggle-${name}`} className={`cursor-pointer flex gap-[10px] items-center ${className}`}>
            <input type="checkbox" ref={ref} hidden value={name} onChange={onChange} id={`toggle-${name}`} onClick={handleClick} />
            {checked[name] ? <TickSquare size={24} variant="Bold" /> : <Stop size={24} color="#1F2734" />}
            <span className="text-primary">{label}</span>
          </label>
        </div>
      )}
    />
  );
});

export const RadioboxGroup = React.forwardRef(({ label, name, control, className, items }, ref) => {
  const [selected, setSelected] = useState(items[0]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <div className="flex flex-col flex-1 self-stretch w-full">
          <RadioGroup
            value={selected}
            onChange={e => {
              onChange(e[label]);
              setSelected(e);
            }}
          >
            <RadioGroup.Label className="sr-only">server size</RadioGroup.Label>
            <div className="flex flex-col items-start gap-5">
              {items.map((item, index) => (
                <RadioGroup.Option
                  key={index + 1}
                  value={item}
                  className={({ active, checked }) =>
                    `w-full cursor-pointer min-h-[60px] px-5 transition-all ease-in-out flex flex-col items-center justify-center rounded-lg ${
                      active ? "text-brand01 bg-brand02 border border-brand01" : " border border-gray04"
                    }`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className={`flex gap-[10px] items-center w-full ${className}`}>
                        <RadioGroup.Label as="p">{item[label]}</RadioGroup.Label>
                        {checked ? <RecordCircle variant="Bold" /> : <Record />}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      )}
    />
  );
});

export const MultiSelectField = React.forwardRef(({ label, name, error, type, control, items, setSelected, selectedItems }, ref) => {
  return (
    <Fieldset className={`border w-full mb-5 ${error && "border-red-500"}`}>
      <legend>{label}</legend>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange }, ...props }) => <MultiSelect items={items} setSelected={setSelected} selectedItems={selectedItems} type={type} />}
      />
    </Fieldset>
  );
});

const MultiSelect = props => {
  const [dropdown, setDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleDropdown = e => {
    e.preventDefault();

    setDropdown(!dropdown);
  };

  // useEffect(() => {
  //   // alert(JSON.stringify(props.selectedItems));
  // }, [props.selectedItems]);

  const addTag = item => {
    // if (Object.values(props.selectedItems).map.indexOf(item.name) > 1) {
    props.setSelected("SET_MULTI_ITEMS", item);
    setDropdown(false);
    // }
  };

  const removeTag = item => {
    props.setSelected("FILTER_MULTI_ITEMS", item);
  };

  return (
    <div className="autocomplete-wrapper w-full">
      <div className="autocomplete">
        <div className="w-full flex flex-col items-center mx-auto">
          <div className="w-full">
            <div className="flex flex-col items-center relative">
              <div className="w-full ">
                <div className="mb-2 p-0 flex border-none border-gray-200 bg-white rounded ">
                  <div className="flex flex-auto flex-wrap">
                    {props.selectedItems.map((tag, index) => {
                      return (
                        <div key={index} className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
                          <div className="text-xs font-normal leading-none max-w-full flex-initial">{tag[props.type]}</div>
                          <div className="flex flex-auto flex-row-reverse">
                            <div onClick={() => removeTag(tag)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%"
                                height="100%"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="flex-1">
                      <input placeholder="" className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800" />
                    </div>
                  </div>
                  <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200" onClick={toggleDropdown}>
                    <span className="absolute cursor-pointer flex items-center inset-y-0 right-0 pr-0 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                    {/* <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none"></button> */}
                  </div>
                </div>
              </div>
            </div>
            {dropdown ? <Dropdown list={props.items} addItem={addTag} type={props.type} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Dropdown = ({ list, addItem, type }) => (
  <div id="dropdown" className="absolute shadow top-100 bg-white z-40 w-4/5 lef-0 rounded max-h-select overflow-y-auto ">
    <div className="flex flex-col w-full">
      {list.map((item, key) => {
        return (
          <div key={key} className="cursor-pointer w-full border-gray-100 rounded-t hover:bg-red-100" onClick={() => addItem(item)}>
            <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
              <div className="w-full items-center flex">
                <div className="mx-2 leading-6 ">{item[type]}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export const SearchDropdown = React.forwardRef(({ label, name, error, control, items, className, loading, disabled, val, placeholder }, ref) => {
  const [selected, setSelected] = useState(); // get the first item from the items list
  const [query, setQuery] = useState(""); // search query...use this to filter what you need from the items

  const handleChange = onChange => e => {
    onChange(e);
    setSelected(e);
  };

  /** filter the items list using the query and assign to the filtered_item variable */
  const filtered_item = useMemo(() => (query === "" ? items : items.filter(item => item[val].toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")))), [query, items]);
  return (
    <Fieldset className={`flex flex-col gap-[10px] flex-1 text-left self-stretch`}>
      <legend>{label}</legend>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, ...props }) => (
          <Combobox value={value ?? selected} onChange={handleChange(onChange)}>
            <div className="relative">
              <div
                className={`px-5 py-[10px] rounded-lg flex placeholder:text-sm outline-none ${error ? "border border-red-500" : "border border-[#E4E4E7]"} ${
                  disabled ? "bg-gray-50 text-gray-400" : "bg-white text-primary"
                } ${className}`}
              >
                <Combobox.Input
                  className=""
                  ref={ref}
                  name={name}
                  placeholder={placeholder || "Select Bank"}
                  displayValue={item => item?.[val] || ""}
                  onChange={e => onChange(setQuery(e.target.value))}
                />
                <Combobox.Button>
                  <ArrowDown2 aria-hidden="true" size={22} color="#1F2734" />
                </Combobox.Button>
              </div>
              <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery("")}>
                {/* <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"> */}

                <Combobox.Options
                  className={clsx("absolute bg-white max-h-[250px] w-full overflow-auto z-50 mt-1 rounded-md")}
                  style={{
                    boxShadow: "0px 2px 20px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  {loading ? (
                    <div className="py-[10px] px-5 w-full flex justify-center items-center">
                      <span>loading banks...</span>
                    </div>
                  ) : (
                    <>
                      {filtered_item.length === 0 && query !== "" ? (
                        <div className="py-[10px] px-5 w-full flex justify-center items-center">No Item was found</div>
                      ) : (
                        <div>
                          {filtered_item.map((item, index) => (
                            <Combobox.Option
                              key={item.id || index + 1}
                              className={({ active }) => `relative cursor-pointer select-none py-[10px] px-5 self-stretch flex flex-col ${active ? "text-[#1F2734] font-medium" : "text-gray01"}`}
                              value={item}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span>{item[val]}</span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        )}
      />
    </Fieldset>
  );
});

export const PinInput = React.forwardRef(({ label, name, error, otp_length, width, control, className }, ref) => {
  const query = useMediaQuery("(min-width: 768px)");

  return (
    <Fieldset className={clsx(`flex flex-col gap-[10px] flex-1 text-left self-stretch`, className)}>
      <legend className="text-base text-gray01">{label}</legend>

      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, ...props }) => (
          <OtpInput
            value={value}
            onChange={onChange}
            placeholder="******"
            numInputs={otp_length || 4}
            containerStyle={{
              display: "flex",
              gap: query ? "20px" : "15px",
            }}
            renderInput={props => (
              <div
                className={clsx(
                  "flex flex-1 items-center justify-center rounded-lg box-border py-[10px] px-5 border",
                  "focus-within:ring-2 focus-within:ring-brand01 focus-within:border-none",
                  width && "h-[44px]"
                )}
                style={{ maxWidth: width }}
              >
                <input {...props} type="password" className={clsx("font-extrabold outline-none focus:outline-none", width && "h-full w-full !text-4xl text-primary")} />
              </div>
            )}
          />
        )}
      />
    </Fieldset>
  );
});

export const FileField = React.forwardRef(({ label, name, className, error, uploadFile, url, control, loading }, ref) => {
  // let { onChange, ref } = register(name);
  const [image, setImage] = useState(null);
  const fileRef = useRef();

  const onFileChange = useCallback(
    onChange => async event => {
      if (event.target.files[0]) {
        const base64 = await getBase64(event.target.files[0]);

        uploadFile(event.target.files[0]);

        setImage(base64);
        onChange(event);
      }
    },
    []
  );
  return (
    <Fieldset
      className={className || `border ${error && "border-red-500"} bg-gray-100 flex justify-center rounded-lg px-5 cursor-pointer overflow-hidden h-28`}
      onClick={() => fileRef.current.click()}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange } }) => (
          <>
            <input type="file" disabled={loading} ref={fileRef} name={name} onChange={onFileChange(onChange)} hidden accept={[".jpg", ".png"]} />
            {!url && !image ? (
              <div className="h-full flex flex-col justify-center items-center">
                <Camera size="28" color="#BDBDBD" />
                <small className="opacity-60">{url || "Drag and drop file here or click to upload. File must be in JPEG format."}</small>
              </div>
            ) : (
              <img
                src={image || url}
                alt="upload here"
                onClick={() => (!url ? fileRef.current.click() : null)}
                className="bg-gray-100 my-2 bg-contain flex justify-center items-center cursor-pointer"
              />
            )}
          </>
        )}
      />
    </Fieldset>
  );
});

export const AssetRadioField = React.forwardRef(({ name, control, label, items }, ref) => {
  const [selected, setSelected] = useState(items[0]);

  const handleChange = onChange => value => {
    //
    onChange(value);
    setSelected(value);
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup ref={ref} value={value ?? selected} onChange={handleChange(onChange)} className={clsx("flex flex-col gap-2.5")}>
            <RadioGroup.Label className={clsx("leading-[20.16px]")}>{label}</RadioGroup.Label>
            <div className={clsx("flex gap-[30px]")}>
              {items.map(item => (
                <RadioGroup.Option key={item?.title} value={item?.title} className={clsx("flex gap-2.5 cursor-pointer")}>
                  {({ active, checked }) => (
                    <>
                      {checked ? <RecordCircle variant="Bold" color="#9A2333" /> : <Record />}
                      <span className="text-primary">{item?.title}</span>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        )}
      />
    </div>
  );
});

export const PaymentRadioField = React.forwardRef(({ name, items = null, label, control }, ref) => {
  const [selected, setSelected] = useState(null);

  const handleChange = onChange => value => {
    //
    onChange(value);
    setSelected(value);
  };

  return (
    <div>
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup ref={ref} value={value ?? selected} onChange={handleChange(onChange)} className={clsx("flex flex-col gap-2.5")}>
              <RadioGroup.Label className={clsx("leading-[20.16px]")}>{label}</RadioGroup.Label>
              <div className={clsx("flex gap-5")}>
                {items.map(item => (
                  <RadioGroup.Option
                    key={item?.title || item}
                    value={item?.value ?? item?.title}
                    className={({ active, checked }) =>
                      clsx(
                        "bg-white cursor-pointer rounded-[15px] border p-5 gap-[35px] flex md:w-[145px] shadow-custom transition-all ease-in-out duration-500",
                        active ? "border-brand01" : "border-[#F8F7F7]"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className={clsx("flex flex-col gap-2.5 text-primary")}>
                          {item?.icon}
                          <p className="leading-[20.16px]">{item?.title}</p>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          )}
        />
      </div>
    </div>
  );
});

export const RadioForm = React.forwardRef(({ name, error, items = null, description, type, onPress, width, input_type, reload }, ref) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (type != "single") setSelected(null);
  }, [type]);

  useEffect(() => {
    if (reload == true) setSelected(null);
  }, [reload]);

  const handleSelected = value => {
    setSelected(value);
    onPress(value);
  };

  return (
    <div className={`${type == "single" ? width || "px-0 w-40" : "px-4 w-full"}`}>
      <div className={`${type == "single" ? "" : "mx-auto"} w-full max-w-md`}>
        <RadioGroup
          value={selected}
          onChange={handleSelected}
          // style={{ width: width }}
          // onClick={() => onPress(selected)}
        >
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className={` ${type == "single" ? "flex space-x-2" : "space-y-2"}`}>
            {items.map(item => (
              <RadioGroup.Option
                key={item[name] || item}
                value={item}
                className={({ active, checked }) =>
                  `${active ? "ring-2 ring-white ring-opacity-60 ring-offset-0 ring-offset-red-300" : ""}
                ${checked ? "bg-opacity-75 text-white" : "bg-white"}
                  relative flex cursor-pointer rounded-lg px-3 ${type == "single" ? `flex-1 ${checked ? "border-red-300" : "border-gray-300"} border-2` : "shadow"} py-5 focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center flex-1">
                        <div className="text-sm flex">
                          <RadioGroup.Label as="p" className={`font-medium text-xs ${checked ? "text-black" : "text-gray-900"}`}>
                            {input_type == "currency" ? currencyFormatter(item[Object.keys(item)] || currencyFormat(item)) : item[Object.keys(item)] || item}
                          </RadioGroup.Label>
                          {type != "single" && (
                            <RadioGroup.Description as="span" className={`inline pl-3 ${checked ? "text-gray-500" : "text-gray-500"}`}>
                              <span>{description || null}</span>
                              {/* <span>{item[name]}</span> */}
                            </RadioGroup.Description>
                          )}
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIconII className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
});

const CheckIconII = props => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#922333" opacity="0.9" />
      <path d="M7 13l3 3 7-7" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const DateField = React.forwardRef(({ error, label, control, name, disabled, placeholder, className }, ref) => {
  const date_format = "YYYY-MM-DD";

  const handleChange = onChange => date => {
    const formatted_date = dayjs(date).format(date_format);
    onChange(formatted_date);
  };

  return (
    <Fieldset className={`flex flex-col gap-[10px] flex-1 text-left self-stretch`}>
      <legend className="text-base text-gray01">{label}</legend>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, ...props }) => (
          <div>
            <DatePicker
              onChange={handleChange(onChange)}
              // value={value ? dayjs(value).format(date_format) : ""}
              disabled={disabled}
              ref={ref}
              placeholder="DD/MM/YYYY"
              format={date_format}
              suffixIcon={<Calendar2 className="text-primary" />}
              className={clsx(
                "px-4 py-2.5 w-full rounded-lg placeholder:text-sm hover:border-[#E4E4E7] focus-within:border-[#E4E4E7] focus-within:ring-0",
                error ? "border border-red-500" : "border border-[#E4E4E7]",
                disabled ? "bg-gray-50 text-gray-400" : "bg-white text-primary",
                className
              )}
            />
          </div>
        )}
      />
    </Fieldset>
  );
});

export const PhoneField = React.forwardRef(({ name, className, placeholder, control, disabled, label, error, countries = countryCodeAndFlag }, ref) => {
  const { setValue } = useFormContext() ?? {}; // for cases where form won't be wrapped in FormProvider, set as empty object

  const default_country = useMemo(() => countries.find(country => country?.calling_code == "234"), [countries]);
  const [selected_country, setSelectedCountry] = useState(default_country); // selected countries
  const [search, setSearch] = useState(""); // for searching for country
  const [phone_number, setPhoneNumber] = useState("");

  useEffect(() => {
    const { calling_code, phone_number_length } = selected_country; // get only calling code and phone number length
    !disabled && setValue && setValue(`${name}_country_data`, { calling_code, phone_number_length });
  }, [selected_country, setValue, name, disabled]); // update the country data whenever the selected country changes

  // for country data and country code
  const handleChange = onChange => country => {
    setSelectedCountry(country);
    setPhoneNumber("");
    onChange("");
  };

  // for phone number
  const handlePhoneNumberChange = onChange => e => {
    setPhoneNumber(e.target.value);
    onChange(e.target.value);
  };

  //filtered countries
  const filtered_countries = useMemo(
    () => (search === "" ? countries : countries.filter(item => item.name?.toLowerCase().replace(/\s+/g, "").includes(search.toLowerCase().replace(/\s+/g, "")))),
    [search, countries]
  );

  const renderCountries = ({ item, index }) => (
    <Listbox.Option
      key={index}
      className={({ active }) => `cursor-default select-none relative w-full py-2 pl-3 pr-9 outline-none hover:text-white ${active ? "text-white bg-gray-400" : "text-gray-900"}`}
      value={item}
    >
      {({ selected, active }) => (
        <div className="flex justify-between self-stretch">
          <div className={clsx("flex items-center gap-2.5")}>
            <img src={`data:image/png;base64,${item?.flag}`} className="rounded" />
            <span className={`block truncate ${selected ? "font-semibold" : "font-medium"}`}>{(item?.name || item).toString().replace(/_/g, " ")}</span>
            <span>+{item?.calling_code}</span>
          </div>

          {selected ? (
            <div className={`${active ? "text-white" : "text-red-800"} absolute inset-y-0 right-0 flex items-center pr-4`}>
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          ) : null}
        </div>
      )}
    </Listbox.Option>
  );

  return (
    <Fieldset className={`flex flex-col gap-[10px] flex-1 text-left self-stretch}`}>
      <legend className="text-base text-gray01">{label}</legend>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <Listbox disabled={disabled} value={selected_country} onChange={handleChange(onChange)}>
            <div className="relative">
              <div className={clsx("flex items-center border rounded-[8px] w-full", disabled ? "bg-gray-50 text-gray-400" : "bg-white text-primary", error ? "border-red-500" : "border-[#E4E4E7]")}>
                <Listbox.Button className={clsx("flex border-r items-center gap-2.5 px-5 pr-1.5 py-[10px]")}>
                  <img src={`data:image/png;base64,${selected_country?.flag}`} alt={selected_country?.name} className="rounded" />
                  <span className="">+{selected_country?.calling_code}</span>
                  <ArrowDown2 size={18} />
                </Listbox.Button>
                <div className="flex-1 px-2 py-1.5 text-primary">
                  <input
                    className={clsx(disabled ? "text-gray-400" : "text-primary")}
                    value={phone_number ?? value}
                    onChange={handlePhoneNumberChange(onChange)}
                    ref={ref}
                    disabled={disabled}
                    maxLength={selected_country?.phone_number_length ?? 11}
                    placeholder={placeholder}
                  />
                </div>
              </div>
              <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className="absolute mt-1 z-50 max-h-60 min-w-[516px] w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <div className="sticky top-0 py-1 pt-3 bg-white px-5 z-50">
                    <input type="text" className="border p-2 rounded-md" placeholder="Search Countries" value={search} onChange={e => setSearch(e.target.value)} />
                  </div>
                  <FlatList data={filtered_countries} renderItem={renderCountries} className="py-1 px-2" />
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        )}
      />
      {error && <CustomCaption message={error.message} />}
    </Fieldset>
  );
});

export const DateRanger = React.forwardRef(({ name, control, label, error, onApply }, ref) => {
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleChange = newVal => {
    setValue(newVal);
    onApply(newVal);
  };
  return (
    <div className="flex flex-col w-full items-start px-5 gap-x-2">
      <div className="">
        <span className="text-xs text-gray-500 font-semibold">Date Range</span>
      </div>
      <div className="w-[100%] border rounded">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <Datepicker
              value={value}
              separator="to"
              useRange={false}
              // showFooter
              showShortcuts
              onChange={onChange}
            />
          )}
        />
      </div>
    </div>
  );
});
