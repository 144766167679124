import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import AuthLayout, { AuthHeader } from "../../../components/Layout/AuthLayout";
import { FormProvider, useForm } from "react-hook-form";
import { DateField, PhoneField, SearchDropdown, SelectField, TextField } from "../../../components/Widget/Form/FormComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { editAction, getAction } from "../../../store/actions/creators/request.action";
import { how_you_heard_about_us } from "../../../utils/helper";
import { showMessage, showModal, clearState, openOfferings } from "../../../store/actions/creators/ui.action";
import AppModal from "../../../components/Widget/Modal/Modal";
import { countryCodeAndFlag } from "../../../utils/country_flag_and_calling_code";
import { allStates } from "../../../utils/nigeriaStateAndLGAs";
import { VALIDATE_PHONE } from "../../../store/actions/action_types/action.types";
import { verifyPhoneURL } from "../../../utils/endpoints";

const Message = ({ closeModal, ...props }) => {
  const navigate = useNavigate();
  return (
    <div className="p-5 flex flex-col">
      <span className="mb-2 text-red-800 text-lg">Congrats</span>
      <span className="text-sm text-gray-600">
        Your Registeration has been completed, <br />
        click the button to login
        <span className="text-lg">👍🏾</span>
      </span>
      <div className="my-3 border-t" />
      <AppButton
        name="Continue"
        className="font-semibold rounded-md outline-none"
        pad=".7em"
        onPress={() => {
          props.clearState("CLEAR_LOGIN");
          navigate(`/auth/login`);
          closeModal();
        }}
      />
    </div>
  );
};

const RegisterStep2 = ({ loading, access_token, success_msg, ...props }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSubmit, formState, control, register, watch, setError, ...rest } = useForm({
    mode: "onChange",
    defaultValues: {
      how_you_heard_about_us_specified: "",
      user_id: location.state?.user_id,
    },
  });
  const { errors, isDirty, isValid } = formState;
  const phone_data = rest.getValues("phone_country_data");
  const watch_phone = watch("phone");

  const onSubmit = async data => {
    data.country_code = data.phone_country_data.calling_code; // do this before changing the state of country
    data.country = data.country.name;

    delete data.phone_country_data; // avoid sending this to the server

    navigate("/auth/offerings", { state: { data } });
  };

  useEffect(() => {
    if (watch_phone && watch_phone?.length == phone_data?.phone_number_length) {
      props.getAction(VALIDATE_PHONE, `${verifyPhoneURL}/${watch_phone}`);
    }
  }, [phone_data, watch_phone]);

  useEffect(() => {
    if (success_msg && success_msg.phone_exists) setError("phone", { message: success_msg.message });
  }, [success_msg]);

  const closeModal = () => props.showModal("HIDE_MODAL", false);

  const is_disabled = !isDirty || !isValid || success_msg?.phone_exists || loading;

  return (
    <>
      <AuthLayout isRegister hasBanner>
        <div className="py-8 xl:py-10 bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full flex flex-col justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
          <AuthHeader name="Tell Us More about you" description="Provide your required details below to complete your registration." className="px-8 xl:px-14" />
          <FormProvider {...rest}>
            <form className="max-h-[500px] px-8 xl:px-14 overflow-auto mt-2 md:mt-5 flex flex-col gap-y-[40px]" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-7">
                <TextField label="First Name" placeholder="Enter your First Name" error={errors.first_name} {...register("first_name", { required: true })} type="text" />
                <TextField label="Last Name" placeholder="Enter your Last Name" error={errors.last_name} {...register("last_name", { required: true })} type="text" />
                <PhoneField control={control} placeholder="08012345678" label="Phone Number" error={errors.phone} {...register("phone", { required: true })} />
                <DateField label="Date Of Birth" control={control} age_range={18} error={errors.date_of_birth} {...register("date_of_birth", { required: true })} />
                <TextField label="Residential Address" placeholder="Enter your Address" error={errors.address} {...register("address", { required: true })} type="text" />
                <SearchDropdown
                  label="Country"
                  placeholder="Select Country"
                  type="text"
                  control={control}
                  val="name"
                  items={countryCodeAndFlag}
                  error={errors.country}
                  {...register("country", { required: true })}
                />
                <SelectField item={allStates} selected_item="state" value="alias" label="State" control={control} error={errors.state} {...register("state", { required: true })} />
                <TextField label="City" type="text" placeholder="Enter your City" error={errors.city} {...register("city", { required: true })} />
                <TextField label="NIN" type="text" placeholder="E.g 123456789" error={errors.nin} {...register("nin", { required: true })} />
                <SelectField
                  item={how_you_heard_about_us}
                  label="How You Heard About Us"
                  control={control}
                  selected_item="penalty_name"
                  error={errors.how_you_heard_about_us}
                  {...register("how_you_heard_about_us", {
                    required: true,
                  })}
                />
                <TextField label="Referral Code (Optional)" placeholder="Enter Referral Code" error={errors.referred_by} {...register("referred_by", { required: false })} type="text" />
              </div>
              <footer className="flex mt-3 flex-col">
                <AppButton name="Next" className="w-fit md:w-[138px] font-medium" disabled={is_disabled} />
              </footer>
            </form>
          </FormProvider>
        </div>
      </AuthLayout>
      <AppModal open={open} onClose={closeModal} content={<Message {...props} closeModal={closeModal} />} />
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.register.verify_phone.loading,
  success_msg: state.register.verify_phone.data,
  user_info: state.auth.login.data,
  access_token: state.register.more_info.access_token,
  open: state.ui.modal.show,
  reg_data: state.register.sign_up.data,
  ip_data: state.auth.get_ip.data,
});

export default connect(mapStateToProps, {
  editAction,
  showMessage,
  showModal,
  getAction,
  clearState,
  openOfferings,
})(RegisterStep2);
