import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import { isToday, isYesterday, parseISO } from "date-fns";
import { AES, enc } from "crypto-js";

export const email = "yustimisu@gmail.com";
export const subject = "";
export const body = "";

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const currencyFormatter = (props, base_currency = null) => {
  const currency = ["."].includes(props) == false ? props + ".00" : props;
  return <CurrencyFormat value={currency} displayType={"text"} thousandSeparator={true} decimalScale={2} prefix={base_currency == "USD" ? "$" : "₦"} renderText={value => <div>{value}</div>} />;
};

export function transformDataToGroupForm(rawData) {
  const groupedTransactionData = rawData.reduce((acc, obj) => {
    const txn_date = obj?.txn_date || obj?.interest_date || obj?.updatedAt;
    let date;

    if (isToday(parseISO(txn_date))) {
      date = "Today";
    } else if (isYesterday(parseISO(txn_date))) {
      date = "Yesterday";
    } else {
      date = formatDate(txn_date, "DD MMM YYYY");
    }
    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(obj);

    return acc;
  }, {});

  const tableInfo = Object.keys(groupedTransactionData).map(date => ({
    title: date,
    data: groupedTransactionData[date],
  }));

  return tableInfo;
}

export const decimalFormatter = props => {
  const amount = props.toFixed(2);
  return amount;
};

export const currencyFormat = num => {
  return parseFloat(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const how_you_heard_about_us = ["DSA", "Search Engine", "Facebook Ads", "Youtube Ads", "Other paid social media advertisement", "Facebook post or group", "Twitter post"];

export const refGenerator = length => {
  let result = "";
  const character = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += character.charAt(Math.floor(Math.random() * character.length));
  }

  return "GDL_" + result;
};

export const gdlAWSConfig = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};

export const headerResolver = pathname => {
  const path_arr = pathname.split("/");
  const path = path_arr[path_arr.length - 1];

  return { path_arr, path: path.replace(/-/g, " ") };
};

export const isValidDate = date => {
  return Boolean(new Date(date).toString() !== "Invalid Date");
};

export const formatDate = (date, format = "DD-MMM-YYYY") => {
  if (date && isValidDate(date)) return dayjs(date).format(format);
  return date;
};

export const formatTimeToAmPm = dateString => {
  const date = new Date(dateString);

  // Get hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine whether it's AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Format minutes with leading zero if needed
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Create the formatted time string
  const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;

  return formattedTime;
};

export function getMinMaxDate(data) {
  const maxDate = data?.reduce((max, date) => {
    return max ? Math.max(max, date.getTime()) : date.getTime();
  });

  const minDate = data?.reduce((min, date) => {
    return min ? Math.min(min, date.getTime()) : date.getTime();
  });

  const max = formatDate(new Date(maxDate), "DD-MMM-YYYY");
  const min = formatDate(new Date(minDate), "DD-MMM-YYYY");
  return {
    max,
    min,
  };
}

export const handleFrequency = frequency_data => {
  return frequency_data.substring(frequency_data.indexOf("-") + 1, frequency_data.indexOf("P")).trim();
};

export const percent = ["0% - Annually", "50% - Annually", "60% - Annually", "70% - Annually", "80% - Annually", "90% - Annually", "100% - Annually"];

export const dcryptPublicKey = (secret, public_key) => {
  let bytes;

  try {
    bytes = AES.decrypt(public_key, secret);
    console.log(bytes);

    const decrypted = bytes.toString(enc.Utf8);
    return decrypted;
  } catch (error) {
    console.log("Unable to Decipher", error);
  }
};
