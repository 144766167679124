import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, LOGIN_ERROR, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  login_loading: false,
  error: false,
  error_msg: null,
  data: null,
  message: null,
  token: null,
  login_token: null,
  session_token: null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.SIGN_IN:
      return {
        ...state,
        login_loading: true,
        loading: false,
        error: false,
        error_msg: null,
      };
    case type.SENDING + "_" + type.VALIDATE_TOKEN:
      return {
        ...state,
        login_loading: true,
        loading: false,
        error: false,
        error_msg: null,
      };
    case type.SIGN_IN:
      return {
        ...state,
        login_loading: false,
        loading: false,
        token: action.payload.data.data.refresh_token,
        login_token: action.payload.data.data.access_token,
        success: action.payload.data.success,
      };
    case type.VALIDATE_TOKEN:
      return {
        ...state,
        login_loading: false,
        loading: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
        // token: action.payload.data.data.refresh_token,
      };
    case "CLEAR_LOGIN":
      return {
        ...state,
        loading: false,
        login_loading: false,
        token: null,
        login_token: null,
        data: null,
        error: false,
        error_msg: null,
      };
    case type.LOGOUT:
      return {
        ...state,
        loading: false,
        login_loading: false,
        token: null,
        data: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        login_loading: false,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        login_loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getIpAddress = (state = {}, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_IP:
      return {
        ...state,
        loading: true,
        login_loading: true,
      };
    case type.GET_IP:
      return {
        ...state,
        loading: false,
        login_loading: false,
        data: action.payload.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        login_loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

// const validateTokenReducer = (state = {}, action) => {
//   switch (action.type) {
//     case type.SENDING:
//       return {
//         ...state,
//         loading: false,
//       };

//     case POST_ERROR_OCCURED:
//       return {
//         ...state,
//         loading: false,
//         error_msg: action.payload,
//       };
//     default:
//       return state;
//   }
// };

const authReducer = combineReducers({
  login: loginReducer,
  get_ip: getIpAddress,
  // validate_token: validateTokenReducer,
});

export default authReducer;
