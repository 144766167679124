import { useForm } from "react-hook-form";
import { AppButton } from "../../Widget/Button/AppButton";
import { AssetRadioField, SelectField, TextField } from "../../Widget/Form/FormComponent";
import { zodResolver } from "@hookform/resolvers/zod";
import { asset_schema } from "../../../schema/assets.schema";
import { CustomCaption } from "../../Common/common";
import { SelfInvestmentForm } from "./self.form";
import { useEffect, useMemo, useRef } from "react";
import { OthersInvestmentForm } from "./others.form";
import { NextOfKinComp, NextOfKinForm } from "./next_of_kin";
import AppModal from "../../Widget/Modal/Modal";
import { connect } from "react-redux";
import { showModal } from "../../../store/actions/creators/ui.action";
import { useNavigate } from "react-router-dom";
import { useHeaderResolver } from "../../../hooks/path.hook";

const product_types = [
  { id: 1, name: "Canary Fund" },
  { id: 2, name: "Money Market Fund" },
  { id: 3, name: "Income Fund" },
]; // should come from an API
const check_data = [{ title: "Self" }, { title: "Others" }]; // should come from an API

const AssetForm = ({ open, user_info, next_of_kin_details, ...props }) => {
  const navigate = useNavigate();
  const { path } = useHeaderResolver();

  const product_type_value = useMemo(() => product_types.find(type => type.name.toLowerCase() == path)); // to know what product type and for setting default value

  // setup default data for self investment and others investment
  const { id, first_name, last_name, phone, email, country, state, address, gender, date_of_birth } = user_info;

  // common value for both self investment and others investment
  const default_country = { name: country };
  const common_value = { first_name, last_name, phone_number: phone, email, country: default_country, state, residential_address: address, gender, date_of_birth };

  // others investment
  // const others_investment = { ...common_value };

  const { formState, register, handleSubmit, watch, control, resetField } = useForm({
    mode: "onChange",
    resolver: zodResolver(asset_schema), // for validation
    defaultValues: {
      user_id: id,
      product_type: product_type_value.name,
      investing_for: "Self",
      self_investment: common_value, // since self investment input data is exactly like common data
    },
  });

  // const form_ref = useRef().current.register

  const { errors, isValid } = formState;
  const watch_radio = watch("investing_for"); //listen for which radio button is chosen

  //  others field. this is needed to clear/reset the input fields if you switch from others to self
  const others_fields = ["first_name", "last_name", "email", "phone_number", "relation", "residential_address", "country", "state", "nationality", "gender", "date_of_birth"];

  useEffect(() => {
    if (watch_radio === "Self") others_fields.forEach(field => resetField(`others_investment.${field}`)); //reset only self input fields
  }, [watch_radio, resetField]);

  const onSubmit = async data => {
    const { others_investment, self_investment } = data; // bring out others_investment and self_investment input fields data

    delete data.others_investment; // remove others_investment from the data to be sent to backend
    delete data.self_investment; // remove self_investment from data to be sent to backend

    const info_data = { ...data, ...(watch_radio !== "Self" ? others_investment : self_investment), next_of_kin_details };
    navigate("/products/assets/bank-details", { state: info_data }); // navigate and send data in state to bank details page
  };

  console.log(errors);

  return (
    <>
      <form className="flex flex-col gap-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <SelectField
            label="Product Type"
            disabled
            error={errors.product_type}
            defaultValue={product_type_value.name}
            className="!bg-white text-primary"
            caption={errors.product_type && <CustomCaption message={errors.product_type.message} />}
            control={control}
            {...register("product_type")}
          />
          <TextField label="Investment Amount" type="currency" control={control} placeholder="₦0.00" error={errors.investment_amount} {...register("investment_amount")} />
          <AssetRadioField label="I am investing for" control={control} items={check_data} {...register("investing_for")} />
          {watch_radio == "Self" ? (
            <SelfInvestmentForm register={register} control={control} errors={errors} />
          ) : (
            <OthersInvestmentForm register={register} control={control} errors={errors} watch={watch} />
          )}
          <NextOfKinComp label="Next Of Kin" />
        </div>
        <AppButton name="Next" className="self-end md:w-[135px]" disabled={!isValid} />
      </form>
      <AppModal open={open} title="Add Next of Kin" className="2xl:w-[850px] xl:w-[500px] lg:w-[500px]" onClose={() => props.showModal("HIDE_MODAL", false)} content={<NextOfKinForm />} />
    </>
  );
};

const mapStateToProps = state => ({
  open: state.ui.modal.show,
  user_info: state.auth.login.data,
  next_of_kin_details: state.ui.set_kin_data.selected_data,
});

export default connect(mapStateToProps, { showModal })(AssetForm);
