import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  error_msg: null,
  data: null,
  success: null,
  message: null,
  token: null,
  request_token: null,
  login_loading: false,
  access_token: null,
  offerings: [],
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.SIGN_UP:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case type.SIGN_UP:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.user,
        request_token: action.payload.data.data.request_token,
        message: action.payload.data.response_description,
      };
    case type.RESEND_OTP:
      return {
        ...state,
        loading: false,
        message: action.payload.data.response_description,
        request_token: action.payload.data.data,
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        loading: false,
        error_msg: null,
        error: false,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        message: null,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const moreInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.MORE_INFO:
      return {
        ...state,
        loading: true,
        error_msg: null,
      };
    case type.MORE_INFO:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        access_token: action.payload.data.data.access_token,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        login_loading: false,
        access_token: null,
        token: null,
        data: null,
        error_msg: null,
        error: false,
      };
    default:
      return state;
  }
};

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.RENEW_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case type.RENEW_PASSWORD:
      // alert(JSON.stringify(action.payload.data.response_description));
      return {
        ...state,
        loading: false,
        message: action.payload.data.response_description,
        data: action.payload.data.data,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        login_loading: false,
        message: null,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const newPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_NEW_PASSWORD:
      return {
        ...state,
        loading: true,
        error_msg: null,
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        error_msg: null,
      };
    case type.ADD_NEW_PASSWORD:
      return {
        ...state,
        loading: false,
        message: action.payload.data.response_description,
        data: action.payload.data.data,
        error_msg: null,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.CHANGE_NEW_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case type.CHANGE_NEW_PASSWORD:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        success: false,
      };
    default:
      return state;
  }
};

const confirmOTPReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.CONFIRM_OTP:
      return {
        ...state,
        loading: true,
      };
    case type.CONFIRM_OTP:
      return {
        ...state,
        loading: false,
        message: action.payload.data.response_description,
        data: action.payload.data,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        login_loading: false,
        message: null,
        error_msg: null,
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        loading: false,
        error_msg: null,
        error: false,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const resendOTPReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.RESEND_OTP:
      return {
        ...state,
        loading: true,
      };
    case type.RESEND_OTP:
      return {
        ...state,
        loading: false,
        message: action.payload.data.response_description,
        request_token: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const pinSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.SET_PIN:
      return {
        ...state,
        loading: true,
      };
    case type.SET_PIN:
      return {
        ...state,
        loading: false,
        message: action.payload.data.response_description,
        data: action.payload.data,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        data: null,
        message: null,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getOfferingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.OUR_OFFERINGS:
      return {
        ...state,
        loading: true,
      };
    case type.OUR_OFFERINGS:
      return {
        ...state,
        loading: false,
        offerings: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const verifyPhoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.VALIDATE_PHONE:
      return {
        ...state,
        loading: true,
      };
    case type.VALIDATE_PHONE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const registerReducer = combineReducers({
  sign_up: signupReducer,
  more_info: moreInfoReducer,
  forgot_password: forgotPasswordReducer,
  new_password: newPasswordReducer,
  confirm_otp: confirmOTPReducer,
  set_pin: pinSetupReducer,
  get_offerings: getOfferingsReducer,
  change_password: changePasswordReducer,
  resend_otp: resendOTPReducer,
  verify_phone: verifyPhoneReducer,
});

export default registerReducer;
