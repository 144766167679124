import React from "react";
import AuthNav from "../Widget/Nav/Nav";
import { AuthContainer } from "./auth.style";
import { InfoCircle } from "iconsax-react";
import { ImageCarousel } from "../Card/Card";
import Autoplay from "embla-carousel-autoplay";
import clsx from "clsx";
import { Link } from "react-router-dom";

const AuthLayout = ({ children, ...props }) => {
  return (
    <AuthContainer>
      <AuthNav {...props} />
      <div
        className={`grid grid-cols-10 overflow-auto sm:bg-transparent bg-white sm:items-center 2xl:items-center xl:items-center lg:items-center items-start self-end gap-2 md:gap-4 2xl:gap-14 flex-1 w-full 2xl:pl-[130px] xl:pl-[100px] lg:pl-[80px] md:pl-[80px] sm:px-0 sm:py-0 px-5 py-5`}
      >
        <div className="flex flex-col justify-center sm:col-span-4 col-span-full">
          <div className="flex-1 flex flex-col justify-center 2xl:gap-7 xl:gap-6 lg:gap-3 gap-3">
            {props.hasBanner && (
              <div className="flex 2xl:p-5 xl:p-4 lg:p-3 p-3 items-end gap-5 bg-brand02 rounded-lg border-[0.5px] border-gray04">
                <div className="flex gap-[10px] items-center flex-1">
                  <InfoCircle color="#FF8A65" />

                  <h4 className="font-medium 2xl:text-sm xl:text-xs lg:text-[10px] text-xs">
                    For safety, check your browser’s address bar that you’re on{" "}
                    <Link to={"https://app.gdl.com.ng"} target="_blank" rel="noreferrer" className="text-brand01">
                      https://app.gdl.com.ng
                    </Link>
                  </h4>
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
        <div className="sm:flex-1 xl:-mt-[150px] h-full col-span-6 justify-center items-center sm:flex hidden">
          <ImageCarousel plugins={[Autoplay()]} options={{ loop: true }}>
            {["/auth-1.png", "/auth2.png", "/auth-3.png"].map((item, index) => (
              <div className="flex-[0_0_100%] " key={index}>
                <img src={item} className={clsx("h-full w-full object-contain")} alt="slider_img" loading="lazy" />
              </div>
            ))}
          </ImageCarousel>
        </div>
      </div>
    </AuthContainer>
  );
};

export const AuthHeader = ({ name, description, className }) => (
  <div className={clsx("w-full flex flex-col items-start gap-[10px] self-stretch", className)}>
    <h3 className="text-lg 2xl:text-[22px] xl:text-[22px] lg:text-[20px] font-medium text-primary capitalize">{name}</h3>
    <small className="leading-5 text-gray01 sm:text-xs lg:text-xs lg:hidden xl:block block xl:text-sm">
      {description || "To log in, please enter your GDL account email and the correct password."}
    </small>
  </div>
);

export default AuthLayout;
