import { connect, useDispatch } from "react-redux";
import HouseMoniHeader from "../../../components/HouseMoni/HouseMoniHeader";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { useForm } from "react-hook-form";
import { PaymentRadioField, TextField } from "../../../components/Widget/Form/FormComponent";
import { CopyView } from "../../../components/Widget/Copy/Copy";
import { CustomLabel } from "../../../components/FundWithdraw/withdrawalcomps";
import { useLocation, useNavigate } from "react-router-dom";
import { currencyFormatter } from "../../../utils/helper";
import { getAction, postAction } from "../../../store/actions/creators/request.action";
import { FUND_CARD, FUND_WITH_NUBAN, GET_HPA_SUBS } from "../../../store/actions/action_types/action.types";
import { fundCardURL, fundWithNubanURL, hpaSubscriptionURL } from "../../../utils/endpoints";
import { useEffect } from "react";
import { clearState, setSubscribed, showMessage } from "../../../store/actions/creators/ui.action";
import AppModal from "../../../components/Widget/Modal/Modal";
import { PaymentDetailView } from "../../../components/HouseMoni/Payment.view";
import { SubscribedBox } from "../../../components/Widget/MessageBox/SubscribedBox";
import { radio_data } from "../../../lib/data";

const message = "GDL HouseMoni successfully subscribed.";

const SubscriptionPayment = ({ token, user_wallet, user_info, ip_data, card_fund_success, nuban_fund_success, ...props }) => {
  const wallet_data = user_wallet[0]?.wallets[0];
  const account_name = `${user_info?.first_name} ${user_info?.last_name}`.toUpperCase();
  const account_number = wallet_data?.nuban_account;

  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // pay with card data
  const card_payment_data = {
    redirect_url: process.env.REACT_APP_CALLBACK_URL + "/housemoni/make-payment",
  };

  // data for nuban payment
  const nuban_data = { bank_code: "GDL Finance", account_name, account_number };

  const { control, register, handleSubmit, formState, watch, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      user_id: user_info?.id,
      wallet_ref: user_info?.user_txn_ref,
      amount: Number(state?.amount),
      service_type: "HPA",
      user_ip: ip_data?.ip || "N/A", // should be gotten
      channel_id: "WEB",
      transaction_location: JSON.stringify({
        long: ip_data?.longitude ?? "N/A",
        lat: ip_data?.latitude ?? "N/A",
      }),
      request_ref: state?.payment_reference ?? state?.request_ref,
      hpa_subscription_id: state?.hpa_subscription_id ?? state?.id,
      ...nuban_data,
      ...card_payment_data,
    },
  });
  const watch_radio = watch("funding_option");
  const { isValid, isDirty } = formState;

  // when you refresh the page and account number isn't currently available
  useEffect(() => {
    if (account_number && !getValues("account_number")) {
      setValue("account_number", account_number);
    }
  }, [account_number, setValue, getValues]);

  useEffect(() => {
    if (card_fund_success) {
      navigate("/checkout");
      dispatch(clearState());
    }

    if (nuban_fund_success) dispatch(setSubscribed("OPEN_SUBSCRIBE"));
  }, [card_fund_success, nuban_fund_success]);

  useEffect(() => {
    if (props.nuban_fund_error) {
      dispatch(showMessage("OPEN_MSG_MODAL", { message: props.nuban_fund_error }));
    }
  }, [props.nuban_fund_error]);

  const onSubmit = data => {
    /** if card payment is chosen, call card payment endpoint */
    if (watch_radio == radio_data[0].value) {
      dispatch(postAction(FUND_CARD, fundCardURL, data, token));
      return;
    }
    // call fund with nuban
    dispatch(postAction(FUND_WITH_NUBAN, fundWithNubanURL, data, token));
    console.log(data);
  };

  const handleBackToOverview = () => navigate("/dashboard");

  const navigateToPortfolio = () => {
    navigate("/housemoni");
    dispatch(getAction(GET_HPA_SUBS, `${hpaSubscriptionURL}/${user_info?.id}`, token));
  };

  const subscribe_obj = { message, btn_name: "Back to Overview", onBack: handleBackToOverview, onViewPortfolio: navigateToPortfolio };

  return (
    <>
      <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[35px]">
        <HouseMoniHeader title="Payment Method" />
        <form className="flex flex-col gap-10 self-stretch" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-10">
            <PaymentRadioField label="Choose preferred payment method" control={control} items={radio_data} {...register("funding_option", { required: true })} />
            {watch_radio === radio_data[1].value && (
              <div className="flex flex-col items-start gap-10 self-stretch">
                <TextField label={"Bank Name"} type="text" disabled className="text-primary !bg-white" {...register("bank_code", { required: false })} />
                <TextField
                  label={<CustomLabel label="Account Number" sub_label="Aval. Balance:" balance={wallet_data?.available_balance ?? 0} />}
                  type="text"
                  disabled
                  className="text-[#1F2734] font-medium !bg-white"
                  rightProps={<CopyView text={account_number} />}
                  {...register("account_number")}
                />
                <TextField label="Account Name" type="text" disabled className="text-[#1F2734] font-medium !bg-white" {...register("account_name", { required: false })} />
              </div>
            )}
            <PaymentDetailView type={watch_radio} amount={state?.amount} />
          </div>
          <AppButton
            name={<span className="flex gap-x-1.5">Pay {currencyFormatter(state?.amount ?? 0)}</span>}
            disabled={!isDirty || !isValid}
            className="self-end w-auto"
            loading={props.card_fund_loading || props.nuban_fund_loading}
          />
        </form>
      </div>
      <AppModal open={props.subscribe_open} no_header className="sm:w-[500px]" onClose={() => null} content={<SubscribedBox {...subscribe_obj} />} />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_wallet: state.account.get_wallets.data,
  user_info: state.auth.login.data,
  confirm_data: state.ui.modal.confirm_data,
  ip_data: state.auth.get_ip.data,
  card_fund_success: state.account.card.success,
  card_fund_loading: state.account.card.loading,
  subscribe_open: state.ui.subscribe.open_subscribe,
  nuban_fund_success: state.account.fund_nuban.success,
  nuban_fund_loading: state.account.fund_nuban.loading,
  nuban_fund_error: state.account.fund_nuban.error_msg,
});

export default connect(mapStateToProps, {})(SubscriptionPayment);
