import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AuthLayout, { AuthHeader } from "../../../components/Layout/AuthLayout";
import { useForm } from "react-hook-form";
import { Checkbox, PasswordField, TextField } from "../../../components/Widget/Form/FormComponent";
import { Link } from "react-router-dom";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { postAction, getAction } from "../../../store/actions/creators/request.action";
import { SIGN_UP, GET_IP } from "../../../store/actions/action_types/action.types";
import { signupURL, ipURL } from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import { showMessage, clearState } from "../../../store/actions/creators/ui.action";
import { ipRequest } from "../../../api/request.api";
import { InfoCircle, TickCircle } from "iconsax-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { signup_schema } from "../../../schema/auth.schema";

const CheckboxLabel = ({ onPress }) => {
  return (
    <div>
      I have read & accepted the{" "}
      <span className="text-green01 cursor-pointer" onClick={onPress}>
        Terms and Conditions
      </span>
    </div>
  );
};

const PasswordChecker = ({ pass }) => (
  <div className="flex flex-col">
    {Object.keys(pass).map((content, index) => (
      <div key={index} className={`flex items-center gap-x-2 ${pass[content] == true ? "text-green01" : "text-brand01"} `}>
        {pass[content] == true ? <TickCircle variant="Broken" size={20} /> : <InfoCircle variant="Bold" size={20} />}

        <span className={`text-sm`}>{content}</span>
      </div>
    ))}
  </div>
);

const RegisterStep1 = ({ loading, ...props }) => {
  const [validTypePass, setValidTypePass] = useState(null);
  const navigate = useNavigate();
  const { handleSubmit, formState, watch, control, register, reset } = useForm({
    mode: "onChange",
    resolver: zodResolver(signup_schema), // validate input fields
  });

  /** if response has an error show the modal message */
  useEffect(() => {
    if (props.error) {
      props.showMessage("OPEN_MSG_MODAL", { message: props.error_msg });
    }
  }, [props.error]);

  useEffect(() => {
    /** watches for the password field to check the authencity of the password */
    const subscription = watch((value, { name, type }) => name == "password" && checkPasswordAuthenticity(value.password));
    return () => subscription.unsubscribe();
  }, [watch]);

  /** check if password entered matches some rules */
  const checkPasswordAuthenticity = value => {
    if (value) {
      setValidTypePass({
        "password contains number": value.match(/["0-9"]/) ? true : false,
        "password contains caps": value.match(/["A-Z"]/) ? true : false,
        "password greater than 8": value.length >= 8 ? true : false,
        "password contains special symbols": value.match(/["$&+,:;=?@#|'<>.^*()%!-"]/) ? true : false,
      });
    } else {
      setValidTypePass(null);
    }
  };

  const onSubmit = async data => {
    props.getAction(GET_IP, ipURL, null, ipRequest);

    data.device_hash = "devicehashweb";
    data.registration_channel = "web";
    if (Object.keys(validTypePass).includes(false)) {
      // alert("wassa");
      return;
    }
    props.postAction(SIGN_UP, `${signupURL}/1`, data);
    reset();
  };

  useEffect(() => {
    if (props.reg_data && Object.keys(props.reg_data).length > 0 && props.request_token != null && !loading) {
      navigate("/auth/verify-email", { state: { email: props.reg_data.email, id: props.reg_data.id } });
      // props.clearState();
    }
  }, [props.reg_data]);

  const openTermsandCond = e => {
    e.preventDefault();
    // open terms and conditions
  };

  return (
    <AuthLayout isRegister hasBanner>
      <div className="py-8 xl:py-10 px-8 xl:px-14 bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full gap-10 flex flex-col justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
        <AuthHeader
          name="Create a GDL Plus Account"
          description="Provide your required details. Your password should exceed 8 characters and include at least one number, one capital letter, and one symbol."
        />
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-5">
            <TextField label="Email Address" placeholder="example@gmail.com" error={formState.errors.email} {...register("email")} type="email" />
            <PasswordField
              label="Password"
              placeholder="******"
              error={formState.errors.password}
              {...register("password")}
              caption={validTypePass != null && <PasswordChecker pass={validTypePass} />}
            />
            <PasswordField label="Confirm Password" placeholder="re-enter password" error={formState.errors.password_confirm} {...register("password_confirm")} />
            <div className="flex gap-x-[3px]">
              <Checkbox label={<CheckboxLabel onPress={openTermsandCond} />} control={control} className="text-[#449E6A]" {...register("agreement", { required: true })} />
            </div>
          </div>
          <footer className="flex flex-col mt-6 xl:mt-14">
            <AppButton name="Continue" className="w-fit xl:w-[138px] font-medium rounded-md" loading={loading} disabled={!formState.isDirty || !formState.isValid} />
            <span className="text-sm mt-2">
              Already have an account?{" "}
              <Link className="text-red-800" to={`/auth/login`}>
                Sign in
              </Link>
            </span>
          </footer>
        </form>
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = state => ({
  loading: state.register.sign_up.loading,
  error: state.register.sign_up.error,
  error_msg: state.register.sign_up.error_msg,
  success_msg: state.register.sign_up.message,
  reg_data: state.register.sign_up.data,
  request_token: state.register.sign_up.request_token,
  user_info: state.auth.login.data,
});

export default connect(mapStateToProps, {
  postAction,
  showMessage,
  clearState,
  getAction,
})(RegisterStep1);
