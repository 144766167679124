import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import AuthLayout, { AuthHeader } from "../../components/Layout/AuthLayout";
import { useForm } from "react-hook-form";
import { PasswordField, TextField } from "../../components/Widget/Form/FormComponent";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { postAction, getAction } from "../../store/actions/creators/request.action";
import { showModal, showMessage, clearState, logOut } from "../../store/actions/creators/ui.action";
import { SIGN_IN, VALIDATE_TOKEN, GET_IP } from "../../store/actions/action_types/action.types";
import { loginURL, validateTokenURL, ipURL } from "../../utils/endpoints";
import { ipRequest } from "../../api/request.api";
import AppModal from "../../components/Widget/Modal/Modal";
import { InfoCircle } from "iconsax-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { signin_schema } from "../../schema/auth.schema";

const Message = ({ navigate, showModal, user_info: { id, phone, has_temp_login_pin, email_verified, email }, ...props }) => (
  <div className="p-5 flex flex-col items-center gap-5">
    <div>
      <span className="mb-2 text-red-800 text-lg flex flex-col items-center">
        <InfoCircle variant="Bold" size={54} color="#9A2333" />
        <span className="text-primary text-center font-bold capitalize">Attention</span>
      </span>
      <span className="text-sm text-gray-600">
        Seems you have not completed you registration <br />
        click on the continue button to finish up <span className="text-lg">👍🏾</span>
      </span>
    </div>

    <AppButton
      name="Continue"
      className="font-semibold rounded-md outline-none"
      pad=".7em"
      onPress={() => {
        showModal("HIDE_MODAL", false);
        props.clearState("CLEAR_LOGIN");

        if (!email_verified) {
          navigate("/auth/verify-email", { state: { email, id, from: "login" } });
          return;
        }
        if (phone == null) {
          navigate(`/auth/more-info`, { state: { user_id: id } });
          return;
        }
        if (!has_temp_login_pin) {
          navigate(`/auth/pin-setup`, {
            state: {
              session_token: props.token,
            },
          });
        }
      }}
    />
  </div>
);

const Login = ({ loading, token, ...props }) => {
  const [isvalid, setIsValid] = useState(false);
  const navigate = useNavigate();

  const { handleSubmit, formState, getValues, register, setValue, reset } = useForm({
    mode: "onChange",
    // resolver: zodResolver(signin_schema),
  });
  const dispatch = useDispatch();
  const is_verified = props.user_info?.phone != null && props.user_info?.has_temp_login_pin && props.user_info?.email_verified;

  useEffect(() => {
    if (props.error == true) props.showMessage("OPEN_MSG_MODAL", { message: props.error_msg, is_login: true });
  }, [props.error]);

  useEffect(() => {
    if (token) {
      const valid_data = {
        token,
        ip: props.ip_data?.ip || "location fetch fail",
        long: props.ip_data?.longitude.toString() || "none",
        lat: props.ip_data?.latitude.toString() || "none",
      };
      dispatch(postAction(VALIDATE_TOKEN, validateTokenURL, valid_data)); // get user data with token
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!props.user_info) {
      return;
    }

    if (props.user_info?.account_status === "BLOCKED") {
      props.showMessage("OPEN_MSG_MODAL", { message: "This account has been blocked, please contact customer support for details", msg_type: "error" });
    }

    if (is_verified) {
      navigate("/dashboard");
      reset();
    }
    if (!is_verified) props.showModal("OPEN_MODAL", true);
  }, [props.user_info, dispatch]);

  const onSubmit = async data => {
    dispatch(postAction(SIGN_IN, loginURL, data));
    dispatch(getAction(GET_IP, ipURL, null, ipRequest));
  };

  return (
    <>
      <AuthLayout hasBanner>
        <div className="py-8 xl:py-10 px-8 xl:px-[51px] bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full flex flex-col 2xl:gap-10 xl:gap-10 lg:gap-8 justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
          <AuthHeader name="Welcome To GDL" />
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-y-5">
              <TextField label="Email Address" placeholder="example@gmail.com" error={formState.errors.email} {...register("email", { required: true })} type="email" />
              <PasswordField label="Password" placeholder="**********" error={formState.errors.password} {...register("password", { required: true })} />
            </div>

            <span className="flex justify-start mt-[10px] 2xl:text-base xl:text-base lg:text-sm">
              Forgot Password?
              <Link className="ml-1 text-brand01 underline underline-offset-4" to={"/auth/forgot-password"}>
                Reset Password
              </Link>
            </span>
            <footer className="flex mt-6 xl:mt-14 flex-col">
              <AppButton name="Login" className="rounded-md w-[100px] xl:w-[138px]" loading={loading} disabled={!formState.isDirty || !formState.isValid} />
              <span className="text-sm mt-2 flex gap-1">
                New User?
                <Link className="text-brand01" to={`/auth/signup`}>
                  Register
                </Link>
              </span>
            </footer>
          </form>
        </div>
      </AuthLayout>
      <AppModal open={props.open} no_header onClose={() => props.showModal("HIDE_MODAL", false)} content={<Message navigate={navigate} {...props} token={token} />} />
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.auth.login.login_loading,
  token: state.auth.login.login_token,
  error_msg: state.auth.login.error_msg,
  error: state.auth.login.error,
  user_info: state.auth.login.data,
  ip_data: state.auth.get_ip.data,
  open: state.ui.modal.show,
});

export default connect(mapStateToProps, {
  postAction,
  getAction,
  showModal,
  showMessage,
  clearState,
  logOut,
})(Login);
