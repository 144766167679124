import { combineReducers } from "redux";
import * as reducer from "./reducers";
import { fixedDepositApi, investApi, savingsApi, authApi } from "../services";

const reducers = combineReducers({
  mode: "development",
  ui: reducer.uiReducer,
  loading: reducer.loadingReducer,
  auth: reducer.authReducer,
  register: reducer.registerReducer,
  account: reducer.accountReducer,
  investment: reducer.investmentReducer,
  saving: reducer.savingsReducer,
  loan: reducer.loanReducer,
  hpa: reducer.hpaReducer,
  user: reducer.userReducer,
  transaction: reducer.transactionReducer,
  notification: reducer.notificationReducer,
  withdrawal: reducer.withdrawalReducer,
  assets: reducer.assetsReducer,
  [authApi.reducerPath]: authApi.reducer,
  [investApi.reducerPath]: investApi.reducer,
  [savingsApi.reducerPath]: savingsApi.reducer,
  [fixedDepositApi.reducerPath]: fixedDepositApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    const new_state = {};
    window.Intercom("shutdown");
    return reducers((state = new_state), action);
  }

  return reducers(state, action);
};

export default rootReducer;
