import { useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { currencyFormatter } from "../../../utils/helper";
import Loader from "../Loader/Loader";

const COLORS = ["#992333", "#00C49F", "#0088FE", "#DFBD85"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

  return `${(percent * 100).toFixed(0)}%`;
};

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.year}
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Sale Actual Value ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Sale Percent ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const AppPieChart = ({ data, loading, balance }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Check if all data values are zeros
  const allZeros = data.every(entry => entry.Amount === 0);

  return (
    <>
      {loading == true ? (
        <div className="w-[45%] flex justify-center items-center">
          <Loader loading={loading} color="#992333" />
        </div>
      ) : (
        <div className="2xl:w-[200px] xl:w-[100px] h-[180px] flex items-start">
          <ResponsiveContainer className="">
            <PieChart>
              <Pie
                dataKey="Amount"
                startAngle={360}
                endAngle={0}
                activeIndex={activeIndex}
                // activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={window.matchMedia("only screen and (max-width: 1280px)").matches ? 45 : 85}
                innerRadius={window.matchMedia("only screen and (max-width: 1280px)").matches ? 30 : 68}
                fill="rgb(244 63 94)"
                paddingAngle={1}
                // label={activeIndex == null ? renderCustomizedLabel : null}
                onMouseEnter={(_, index) => setActiveIndex(index)}
                onMouseLeave={(_, index) => setActiveIndex(null)}
              >
                {data?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={allZeros ? "#d3d3d3" : entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default AppPieChart;
