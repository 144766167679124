import { Warning2 } from "iconsax-react";
import { AppButton } from "../Button/AppButton";
// import ComingSoon from "../../../assets/images/ComingSoon.svg";
import { useDispatch } from "react-redux";
import { showModal } from "../../../store/actions/creators/ui.action";

export const EmptyView = ({ title }) => (
  <div className="flex flex-col items-center gap-[10px] self-stretch py-3 h-full">
    <span className="text-primary text-[20px] font-medium">Nothing here</span>
    <span className="text-gray01 text-center text-[14px]">There’s no data yet. {title || "Transactions"} will appear here when it’s available.</span>
  </div>
);

export const EmptyView2 = ({ has_btn, title, btn_name, description, onPress }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center justify-center bg-white rounded-lg py-[51px]">
      <div className="max-w-[415px] h-auto rounded-lg">
        <div className="flex flex-col items-center justify-center h-full bg-white rounded-lg gap-14">
          <div className="bg-[#F3F3F3] flex justify-center items-center w-[160px] h-[160px] rounded-full">
            <img src="/empty-safe.svg" alt="empty safe" className="w-[91px] h-[114px] opacity-50" />
          </div>
          <div className="text-center flex flex-col gap-2.5">
            <h2 className="font-medium text-primary text-[22px]">{title || "No data yet"}</h2>
            <span className="text-center">
              {description ? description : `You do not have any ${title?.toLowerCase() || "savings"} subscription, add new subscription by clicking on the button below`}
            </span>
          </div>
          {has_btn && (
            <div className="">
              <AppButton name={btn_name ?? "Get Started"} size={"202px"} onPress={onPress ? onPress : () => dispatch(showModal("OPEN_MODAL", true))} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ComingSoonDescription = "Exciting updates on the way! Stay tuned for new features and enhancements to elevate your experience.";

export const EmptyView3 = ({ onPress, desc, ...props }) => (
  <div className="flex flex-col items-center justify-center p-20 bg-white w-[100%]">
    <div className="w-[170px] h-[170px] rounded-[90px] bg-brand02">
      <img src={ComingSoon} />
    </div>

    <div className="mt-6 mb-1 text-[14px] font-semibold">Coming soon...</div>

    <div className="w-[40%] text-center mb-6 text-xm">{desc}</div>

    <AppButton name={props.buttonTitle} onPress={onPress} className="xl:px-5" />
  </div>
);
