import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import * as pages from "../pages";
import DashboardLayout from "../components/Layout/DashboardLayout";
import FundRoute from "./FundRoute/fundoption.route";
import WithdrawalRoute from "./WithdrawalRoute/withdrawal.route";
import HpaRoute from "./HpaRoute/hpa.route";
import TransactionRoute from "./TransactionRoute/transaction.route";
import ProfileRoute from "./ProfileRoute/profile.route";
import NotificationRoute from "./NotificationRoute/notification.route";
import Checkout from "../pages/FundWallet/Checkout";
import PaymentSummary from "../pages/FundWallet/PaymentSummary";
import { logOut, activateIdle } from "../store/actions/creators/ui.action";
import ProductsRoute from "./productsRoute/product.route";
import HelpRoute from "./HelpRoute/help.route";
import IdleTimer from "../components/Layout/idleTimer";

const AppRoute = ({ activateIdle, logOut, ...props }) => {
  const timeout = 1800000;
  return (
    <>
      <IdleTimer timeout={timeout} activateIdle={activateIdle} logOut={logOut} /> {/** make this a hook instead */}
      <Routes>
        <Route path="/" element={<DashboardLayout {...props} />}>
          <Route path="dashboard" element={<pages.Home />} />
          <Route path="products/*" element={<ProductsRoute />} />
          <Route path={`/dashboard/fund-account/*`} element={<FundRoute />} />
          <Route path={`withdrawal/*`} element={<WithdrawalRoute />} />
          <Route path="housemoni/*" element={<HpaRoute />} />
          <Route path="profile/*" element={<ProfileRoute />} />
          <Route path="transactions/*" element={<TransactionRoute />} />
          <Route path="notifications/*" element={<NotificationRoute />} />
          <Route path="help/*" element={<HelpRoute />} />
        </Route>
        <Route>
          <Route path="/checkout/redirect" element={() => <PaymentSummary />} />
          <Route path="/checkout" element={<Checkout />} />
        </Route>
      </Routes>
    </>
  );
};

export default connect(null, { activateIdle, logOut })(AppRoute);
