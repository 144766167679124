import React from "react";
import Loader from "../Loader/Loader";
import { Button } from "./appbutton.style";
import clsx from "clsx";

export const AppButton = ({ onPress, loading, ...props }) => {
  if (props.variant === "outline") {
    return (
      <Button
        type={props.type}
        {...props}
        className={`py-2 px-5 cursor-pointer border rounded-[10px] ${props.className} border-brand01 text-brand01 disabled:bg-opacity-50`}
        onClick={onPress}
        disabled={props.disabled}
      >
        {props.icon && props.icon}
        {loading !== true ? props.name : <Loader loading={loading} />}
      </Button>
    );
  }

  return (
    <button
      {...props}
      className={clsx(
        `py-2 2xl:px-5 xl:px-2 lg:px-2 px-2 cursor-pointer flex border rounded-[10px] border-brand01 bg-brand01 justify-center items-center text-white disabled:border-opacity-10 disabled:bg-opacity-50 disabled:cursor-not-allowed`,
        props.className
      )}
      onClick={onPress}
      disabled={loading || props.disabled}
    >
      <span className="flex items-center 2xl:gap-x-2.5 xl:gap-x-1 gap-x-2 justify-center">
        {props.icon && props.icon}
        {loading !== true ? props.name : <Loader loading={loading} />}
      </span>
    </button>
  );
};
