import "./sidebar.css";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { SideBarContainer } from "./sidebar.style";
import { connect } from "react-redux";
import { openSideBar } from "../../store/actions/creators/ui.action";
import { Chart21, House, ArrowSwapHorizontal, Graph, Profile, Logout, Send2 } from "iconsax-react";
import { Link } from "react-router-dom";
import { logOut } from "../../store/actions/creators/ui.action";

const List = props => {
  const location = useLocation();

  if (props.onPress) {
    return (
      <button
        className={`text-sm 2xl:text-[15px] xl:text-sm hover:text-black 2xl:h-[44px] hover:bg-gray-200 transition ease-in-out flex items-center gap-x-3 hover:rounded-lg font-medium px-2 lg:px-[12px] py-2.5`}
        onClick={props.onPress}
      >
        {props.icon}
        {props.list_name}
      </button>
    );
  }

  return (
    <Link
      to={props.url}
      className={`text-sm 2xl:text-[15px] xl:text-sm  2xl:h-[44px]  flex items-center gap-x-3 hover:rounded-lg font-medium px-2 lg:px-[12px] py-2.5 ${
        props.url.split("/")[1] === location.pathname.split("/")[1] ? "text-white bg-[#9A2333] rounded-lg" : "text-[#666666] hover:bg-gray-200 hover:text-black transition ease-in-out"
      }`}
      onClick={props.onPress}
    >
      {props.icon}
      {props.list_name}
    </Link>
  );
};

const Sidebar = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const wallet_length = props.user_wallet?.length - 1; // use to run check if user has housemoni subscription

  const NAVDATA = [
    {
      name: "Overview",
      icon: <Chart21 size="18" variant={location.pathname === "/dashboard" ? "Bold" : "Outline"} />,
      url: "/dashboard",
    },
    {
      name: "Housemoni",
      url: "/housemoni",
      icon: <House size="18" variant={location.pathname === "/housemoni" ? "Bold" : "Outline"} />,
    },
    {
      name: "Products",
      url: "/products",
      icon: <Graph size="18" variant={location.pathname.split("/")[1] === "products" ? "Bold" : "Outline"} />,
    },
    {
      name: "Payments",
      url: "/withdrawal",
      icon: <Send2 size="18" variant={location.pathname === "/withdrawal" ? "Bold" : "Outline"} />,
    },
    {
      name: "Transactions",
      url: "/transactions",
      icon: <ArrowSwapHorizontal size="18" variant={location.pathname === "/transactions" ? "Bold" : "Outline"} />,
    },
  ];

  return (
    <SideBarContainer className={`max-w-[25%] bg-white lg:max-w-[12%] xl:max-w-[18%] 2xl:max-w-[260px] ${props.side_bar == false ? "slideOut" : "slideIn"}`} onClick={() => props.openSideBar(false)}>
      <div className="py-5 bg-white w-full flex flex-col justify-between h-full">
        <div>
          <img src={"/logo.svg"} alt="" className="w-[28px] lg:w-[40px] lg:h-[60px] 2xl:w-[28px] h-[40px] 2xl:h-[40px] mx-auto" />
          <div className="px-2 xl:px-2 xl:pb-4 2xl:px-4">
            <ul className="flex flex-col gap-y-[4px] py-6 2xl:py-[30px] xl:py-[10px] list-none">
              {NAVDATA.map(item => (
                <List key={item.name} list_name={item.name} icon={item.icon} url={item.url} />
              ))}
            </ul>
          </div>
        </div>

        <div className="px-2 xl:px-2 2xl:px-4 border-t border-[#E4E4E7]">
          <ul className="flex flex-col gap-y-[4px] pt-8 2xl:pt-[50px] xl:pt-[40px] lg:pt-[30px] list-none">
            <li className="rounded-2xl bg-[#9A2333] py-[14px] px-2 2xl:px-5 xl:px-4 mb-[16px]">
              <img src="/house.svg" className="2xl:w-[99px] xl:w-[88px] mx-auto 2xl:h-[62px] xl:h-[52px]" />
              <h3 className="text-[#EEF4F4] text-left text-sm 2xl:text-base xl:text-[13px] font-semibold">Own a Home With HouseMoni</h3>
              <p className="text-[#EEF4F4] text-left py-2 2xl:text-[11px] xl:text-[10px] 2xl:leading-normal xl:leading-tight">
                Advance from tenant to homeowner in just 3 years, with fixed, bite-sized, monthly payments
              </p>
              <button
                className="bg-white rounded-[7px] 2xl:text-base xl:text-[15px] w-[80%] mx-auto xl:w-full text-[#992333] py-1 2xl:py-2 xl:py-1.5 flex justify-center items-center"
                onClick={() => navigate("/housemoni")}
              >
                {props.user_wallet[wallet_length]?.wallets[0]?.available_balance <= 0 ? "Get Started" : "View Plan"}
              </button>
            </li>
            <List list_name="Help" icon={<AiOutlineQuestionCircle size={18} />} url="/help" />
            <List list_name="Account" icon={<Profile size="18" variant={location.pathname === "/profile" ? "Bold" : "Outline"} />} url="/profile" />
            <List list_name="Logout" icon={<Logout size="18" variant="Outline" />} onPress={() => props.logOut()} />
          </ul>
        </div>
      </div>
    </SideBarContainer>
  );
};

const mapStateToProps = state => ({
  side_bar: state.ui.sidebar_open.sidebar,
  user_wallet: state.account.get_wallets.data,
});

export default connect(mapStateToProps, { openSideBar, logOut })(Sidebar);
