import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { SearchDropdown, TextField, ToggleField } from "../../components/Widget/Form/FormComponent";
import { useForm } from "react-hook-form";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { postAction, getAction, deleteAction } from "../../store/actions/creators/request.action";
import { ADD_BENEFICIARY, GET_BANKS, GET_BENEFICIARY, DELETE_BENEFICIARY, TRANSFER_FEE } from "../../store/actions/action_types/action.types";
import { bankURL, userBeneficiaryURL, beneficiaryURL, transferFeeURL } from "../../utils/endpoints";
import { processBanks } from "./withdraw.data";
import AppModal from "../../components/Widget/Modal/Modal";
import { showModal, showMessage, clearState, showDeleteModal } from "../../store/actions/creators/ui.action";
import WithdrawalPin from "./WithdrawalPin";
import { useNavigate, useLocation } from "react-router-dom";
import Wrapper from "../../components/FundWithdraw/Wrapper";
import { PasteComp } from "../../components/Widget/Copy/Paste";
import { Beneficiary } from "./Beneficiary";
import { AccountNumberLabel, AmountLabel, CaptionComp } from "../../components/FundWithdraw/withdrawalcomps";
import toast from "react-hot-toast";
import { DeleteBox } from "../../components/Widget/MessageBox/DeleteBox";
import { IncomeCaption } from "../../components/HouseMoni/HouseMoni";
import { useVerifyAccount } from "../../hooks/account.hook";

const Withdrawal = ({ token, loading, delete_id, beneficiaries, fee_loading, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [openBeneficiary, setOpenBeneficiary] = useState(false);

  const is_gdl_account = location.pathname.split("/").includes("gdl-account");
  const title = is_gdl_account ? "Send to GDL" : "Send to Other Banks";
  const transfer_type = is_gdl_account ? "INTERNAL" : "EXTERNAL";

  const { control, formState, register, handleSubmit, watch, getValues, setValue, reset, setError } = useForm({
    mode: "onSubmit",
    defaultValues: {
      bank_code: is_gdl_account && "GDL Finance",
      amount: "",
    },
  });
  const { errors, isDirty, isValid } = formState;

  const watch_acct_num = watch("account_number");
  const watch_bank_code = watch("bank_code");
  const watch_beneficiary = watch("beneficiary"); // toggle to add beneficiary
  const watch_amount = watch("amount");

  const user_balance = useMemo(() => props.user_wallet[0]?.wallets[0]?.available_balance ?? "0", [props.user_wallet]); // get user balance from nuban
  const account_name = props.verify_data?.account_name ?? props.verify_data?.Name; // either gdl account name or other account name

  useVerifyAccount(watch_acct_num, watch_bank_code, is_gdl_account, getValues); //hook to verify account by bank code and account number

  // to determine if beneficiary toggler should show
  const is_beneficiary = useMemo(() => {
    if (!account_name || !beneficiaries) return false;

    return beneficiaries?.some(beneficiary => beneficiary.receiver_account_name === account_name);
  }, [account_name, beneficiaries]);

  useEffect(() => {
    /** call api to get all banks if transferring to other bank */
    if (!is_gdl_account && !props.banks?.length) props.getAction(GET_BANKS, bankURL, token); // get all banks for select field

    /** get beneficiaries when page loads */
    props.getAction(GET_BENEFICIARY, `${userBeneficiaryURL}/${props.user_info?.id}/${transfer_type}`, token);
  }, [is_gdl_account, props.user_info, transfer_type]);

  useEffect(() => {
    props.clearState();

    // if successfully deleted a beneficiary
    if (props.delete_success) {
      props.showDeleteModal("CLOSE_DELETE_MODAL");
      toast.success("Beneficiary was deleted successfully"); // might replace this with a modal
      props.getAction(GET_BENEFICIARY, `${userBeneficiaryURL}/${props.user_info?.id}/${transfer_type}`, token);
    }

    return () => props.clearState(); // when I unmount  i.e navigate out of the current page clear everything on that transfer page
  }, [props.delete_success, location.pathname, props.clearState]);

  useEffect(() => {
    /** watch if beneficiary was toggled  */
    if (watch_beneficiary) {
      const data = {
        user_id: props.user_info.id,
        receiver_account_name: account_name,
        receiver_account_number: watch_acct_num,
        receiver_bank_name: getValues("bank_code").label ?? "GDL Finance",
        receiver_bank_code: is_gdl_account ? "000" : getValues("bank_code").value,
        transfer_type: is_gdl_account ? "INTERNAL" : "EXTERNAL", // to know the type of beneficiary
      };
      props.postAction(ADD_BENEFICIARY, beneficiaryURL, data, token);
    }
  }, [watch_beneficiary]);

  /** set show to be true if verify api was successful without any error */
  useEffect(() => {
    if ((props.verify_data || props.verify_loading) && watch_acct_num?.length == 10) {
      setShow(true);
    } else {
      setShow(false);
    }

    if ((props.verify_error || props.verify_data?.ResponseMessage?.trim() == "Invalid Account") && watch_acct_num?.length == 10 && !props.verify_loading) {
      props.showMessage("OPEN_MSG_MODAL", { message: `${props.verify_error_msg ?? props.verify_data?.ResponseMessage?.trim()}` });
    }
  }, [props.verify_error, props.verify_data, props.verify_loading, watch_acct_num]);

  useEffect(() => {
    /** check if beneficiary was selected then set account number to that beneficiary account number without calling verify endpoint */
    if (props.beneficiary_details) {
      const { account_number, bank_name, bank_code } = props.beneficiary_details;
      setValue("account_number", account_number);
      !is_gdl_account && setValue("bank_code", { value: bank_code, label: bank_name });
    }
  }, [props.beneficiary_details, setValue]);

  /** handle cancel and go to previous page */
  const handleCancel = () => {
    navigate(-1);
  };

  // function to paste possible account number
  const handlePaste = async () => {
    if ("clipboard" in navigator) {
      const text = await navigator.clipboard.readText();
      setValue("account_number", text);
    }
  };

  useEffect(() => {
    if (props.fee && account_name) setOpen(true);
  }, [props.fee, account_name, setOpen]);

  /** submit data to make withdrawal */
  const onSubmit = data => {
    //check if balance is less than 20 naira...don't transfer, show an error
    if (Number(data.amount) < 20) {
      setError("amount", { message: "Amount too low" });
      return;
    }
    // call fee endpoint to get fee for this transfer if it's not gdl to gdl account
    if (is_gdl_account) {
      setOpen(true);
      return;
    }
    const fee_data = { user_id: props.user_info?.id, txn_service_code: "GSER_002_TB", txn_category_code: "GTC_001_TF", txn_amount: data.amount };
    props.postAction(TRANSFER_FEE, transferFeeURL, fee_data, token);
  };

  // function to open beneficiary modal
  const handleBeneficiary = () => {
    setOpenBeneficiary(true);
  };

  // function to delete beneficiary
  const handleDelete = () => {
    props.deleteAction(DELETE_BENEFICIARY, `${beneficiaryURL}/${delete_id}`, token);
  };

  const bank_code = getValues("bank_code");

  /** for updating the view if transfer was successfull */
  useEffect(() => {
    if (props.withdraw_success) {
      setOpen(false);
      props.showMessage("OPEN_MSG_MODAL", { message: `Money sent to ${is_gdl_account ? bank_code : bank_code?.label} successfully!`, title: "Sent!", msg_type: "success" });
      reset();

      props.clearState();
    }
  }, [props.withdraw_success]);

  // function to close transaction pin
  const handleCloseTxnPin = () => {
    if (!props.withdraw_loading) {
      setOpen(false);
    }
  };

  const all_banks = props.banks?.map(processBanks); // get all banks and process the data
  const is_valid_amount = Number(user_balance.replace(/,/g, "")) >= Number(watch_amount ?? "0");
  const is_disabled = !isDirty || !isValid || props.verify_loading || !account_name || !is_valid_amount || watch_amount <= 0;

  return (
    <>
      <Wrapper name={title} no_desc>
        <form className="flex flex-col items-end gap-10 self-stretch" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start gap-10 self-stretch">
            {is_gdl_account ? (
              <TextField label={"Bank"} type="text" disabled className="text-primary !bg-white" error={errors.bank_code} {...register("bank_code", { required: false })} />
            ) : (
              <SearchDropdown
                label="Bank"
                placeholder="Enter Recipient Bank"
                type="text"
                control={control}
                loading={props.loading_banks}
                val="label"
                items={all_banks}
                error={errors.bank_code}
                {...register("bank_code", { required: true })}
              />
            )}
            <TextField
              label={<AccountNumberLabel onPress={handleBeneficiary} />}
              type="text"
              caption={show && <CaptionComp account_name={account_name} loading={props.verify_loading} />}
              placeholder="Enter Recipient Account Number"
              rightProps={<PasteComp handlePaste={handlePaste} />}
              error={errors.account_number}
              {...register("account_number", { required: true })}
            />
            <TextField
              label={<AmountLabel balance={user_balance} />}
              type="currency"
              placeholder="₦0.00"
              control={control}
              error={errors.amount}
              caption={!is_valid_amount && <IncomeCaption message={"Insufficient balance for this transaction"} />}
              {...register("amount", { required: true })}
            />
            <TextField label="Add Note (optional)" type="text" placeholder="Add a Note (optional)" {...register("narration", { required: false })} />
            {props.verify_success && !is_beneficiary && <ToggleField label="Save as beneficiary" className="gap-[54px]" control={control} {...register("beneficiary", { required: false })} />}
          </div>
          <div className="flex justify-end items-center gap-7 self-stretch">
            <AppButton name="Cancel" type="button" variant="outline" className="w-[138px]" onPress={handleCancel} />
            <AppButton name="Next" className="w-[138px]" disabled={is_disabled} loading={fee_loading} />
          </div>
        </form>
      </Wrapper>

      <AppModal open={open} title={title} className="md:w-[600px] gap-[40px]" onClose={handleCloseTxnPin} content={<WithdrawalPin formData={getValues()} is_gdl_account={is_gdl_account} />} />
      <AppModal
        open={openBeneficiary}
        title="Select Beneficiary"
        className="md:w-[600px] md:min-h-[700px] pb-[40px]"
        onClose={() => setOpenBeneficiary(false)}
        content={<Beneficiary onClose={() => setOpenBeneficiary(false)} />}
      />
      <AppModal
        open={props.show_delete_modal}
        className="sm:w-[500px]"
        no_header
        onClose={() => props.showDeleteModal("CLOSE_DELETE_MODAL")}
        content={<DeleteBox onDelete={handleDelete} loading={props.deleting_beneficiary} />}
      />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  banks: state.account.banks.data,
  loading_banks: state.account.banks.loading,
  verify_data: state.withdrawal.verify_account.data,
  verify_loading: state.withdrawal.verify_account.sending,
  verify_success: state.withdrawal.verify_account.success_msg,
  verify_error: state.withdrawal.verify_account.error,
  verify_error_msg: state.withdrawal.verify_account.error_msg,
  open_pin: state.ui.modal.show_profile_modal,
  user_wallet: state.account.get_wallets.data,
  user_info: state.auth.login.data,
  security_question: state.user.set_questions.success,
  user_details: state.user.user_details.data,
  beneficiary_details: state.ui.beneficiary.beneficiary_data,
  show_delete_modal: state.ui.delete_modal.open_delete_modal,
  delete_id: state.ui.delete_modal?.delete_id,
  deleting_beneficiary: state.account.delete_beneficiary.loading,
  delete_success: state.account.delete_beneficiary.success,
  beneficiaries: state.account.get_beneficiaries.data,
  withdraw_success: state.account.withdraw.success,
  withdraw_loading: state.account.withdraw.loading,
  fee: state.withdrawal.fee.data,
  fee_loading: state.withdrawal.fee.sending,
});
export default connect(mapStateToProps, {
  postAction,
  getAction,
  showModal,
  showMessage,
  clearState,
  showDeleteModal,
  deleteAction,
})(Withdrawal);
