import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./App.css";
import Layout from "./components/Layout/Layout";
import IndexRoute from "./routes/indexRoute";
import AppModal from "./components/Widget/Modal/Modal";
import { showMessage, logOut, showModal } from "./store/actions/creators/ui.action";
import MessageBox from "./components/Widget/MessageBox/MessageBox";
import SuccessModal from "./components/Widget/Modal/SuccessModal";
import clsx from "clsx";

const App = ({ show_msg, modal_content, ...props }) => {
  const [leftpage, setLeftpage] = useState(false);
  // document.addEventListener("visibilitychange", handleVisibilityChange);

  useEffect(() => {
    handleVisibilityChange();
  }, []);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      console.log("User left the page");
      setLeftpage(true);
    }
  };

  return (
    <>
      <Layout>
        <IndexRoute />
      </Layout>
      <AppModal open={show_msg} no_header onClose={() => null} className="md:w-[400px]" content={props.msg_content || <MessageBox />} />
      <AppModal open={props.show_success} onClose={() => props.showModal("CLOSE_SUCCESS_MODAL")} z_index={"z-50"} content={<SuccessModal content={props.success_msg} />} />
      <AppModal title="Testing" open={props.open_modal} className={clsx("md:w-[600px] md:min-h-[700px] pb-[40px]")} onClose={() => props.showModal("CLOSE_MODAL")} content={modal_content} />
    </>
  );
};

const mapStateToProps = state => ({
  show_msg: state.ui.open_msg.msg_modal,
  open_modal: state.ui.new_modal.show_modal || false,
  modal_content: state.ui.new_modal.options?.content,
  show_success: state.ui.open_success.open_success,
  success_msg: state.ui.open_success.response_msg,
  msg_content: state.ui.open_msg.content,
  token: state.auth.login.token,
});

export default connect(mapStateToProps, { showMessage, logOut, showModal })(App);
