import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AuthLayout, { AuthHeader } from "../../../components/Layout/AuthLayout";
import { useForm } from "react-hook-form";
import { PinInput } from "../../../components/Widget/Form/FormComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { postAction } from "../../../store/actions/creators/request.action";
import { CONFIRM_OTP, RESEND_OTP } from "../../../store/actions/action_types/action.types";
import { confirmOtpURL, resendOtpURL } from "../../../utils/endpoints";
import { showMessage, clearState } from "../../../store/actions/creators/ui.action";

const OtpConfirm = ({ loading, token, ...props }) => {
  const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  const {
    state: { email, from, id },
    pathname,
  } = useLocation();
  const [countdown, setCountdown] = useState(59);
  const { handleSubmit, formState, control, register } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let interval = null;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setResend(true);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  // function to handle resending otp when timer expires
  const handleResendOTP = () => {
    const data = { email, user_id: id };

    props.postAction(RESEND_OTP, resendOtpURL, data, token);
  };

  /** if user did not complete otp setup and is coming from login page...resend otp */
  useEffect(() => {
    if (from) {
      handleResendOTP();
      navigate(pathname, { replace: true, state: { email, id } });
    }
  }, [from, navigate]);

  const onSubmit = async data => {
    data.request_token = props.request_token.toString();
    data.email = email;
    props.postAction(CONFIRM_OTP, confirmOtpURL, data);
  };

  useEffect(() => {
    if (props.confirm_msg == "Success") {
      navigate("/auth/more-info", { state: { user_id: props.confirm_data.data.id } });
      props.clearState();
    }
  }, [props.confirm_msg]);

  useEffect(() => {
    if (props.error) {
      props.showMessage("OPEN_MSG_MODAL", props.error_msg);
      // props.clearState();
    }
  }, [props.error]);

  return (
    <AuthLayout isRegister hasBanner>
      <div className="py-8 xl:py-10 px-8 xl:px-14 bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full flex gap-7 flex-col justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
        <AuthHeader name="Verify Your Email" description="An OTP was sent to your email. Enter it to confirm your email." />
        <form className="flex flex-col gap-y-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start self-stretch gap-5">
            <PinInput otp_length={6} name="request_otp" control={control} {...register("request_otp", { required: true })} />
            {resend ? (
              <span className="underline underline-offset-2 text-brand01 text-sm cursor-pointer" onClick={handleResendOTP}>
                Resend OTP
              </span>
            ) : (
              <span className="text-gray01 text-sm">Resend OTP in 0:{countdown.toString().padStart(2, "0")}</span>
            )}
          </div>
          <footer className="flex mt-3 flex-col">
            <AppButton name="Next" className="w-[138px] font-medium" loading={loading} disabled={!formState.isDirty || !formState.isValid} />
          </footer>
        </form>
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = state => ({
  loading: state.register.confirm_otp.loading,
  error_msg: state.register.confirm_otp.error_msg,
  error: state.register.confirm_otp.error,
  confirm_msg: state.register.confirm_otp.message,
  confirm_data: state.register.confirm_otp.data,
  request_token: state.register.sign_up.request_token,
});

export default connect(mapStateToProps, {
  postAction,
  showMessage,
  clearState,
})(OtpConfirm);
