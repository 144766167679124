import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { user_endpoint } from "../../utils/endpoints";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${user_endpoint}`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.auth.login.token;
      if (token) {
        headers.set("bearerauth", token);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    validateToken: builder.query({
      query: validData => ({ url: `/auth/validate/token`, method: "POST", body: validData }),
      transformResponse: response => {
        return response.data;
      },
    }),
  }),
});

export const { useValidateTokenQuery } = authApi;

export const { validateToken } = authApi.endpoints;
