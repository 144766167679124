import { useForm } from "react-hook-form";
import { TextField } from "../../components/Widget/Form/FormComponent";
import { FlatList } from "../../components/Widget/List/List";
import { Add } from "iconsax-react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBeneficiaryData, showDeleteModal } from "../../store/actions/creators/ui.action";
import { processBeneficiaries } from "./withdraw.data";
// import EmptyImg from "../../assets/images/empty_beneficiary.svg";
import { BeneficiarySkeleton } from "../../components/Widget/Loader/Skeleton";

const colors = ["bg-[#449E6A]", "bg-[#9A2333]", "bg-[#3BB3C2]", "bg-[#E0BD85]", "bg-[#1F2734]"];

export const BeneficiaryComp = ({ id, account_name, account_number, bank_name, initials, onSelect, onDelete, index }) => {
  const random_index = index % colors.length; // Calculate a unique color index for each item
  const random_color = colors[random_index]; // Get the color based on the index

  return (
    <div className="flex justify-between p-[20px_0px] self-stretch items-center">
      <div className="flex gap-[12px] items-center cursor-pointer flex-1" onClick={onSelect}>
        <div className={`${random_color} md:w-[42px] flex justify-center items-center md:h-[42px] rounded-full p-[9px_12px]`}>
          <span className="text-[#FFFFFF]">{initials}</span>
        </div>
        <div className="flex flex-col gap-[10px] items-start">
          <span className="font-medium text-[#1F2734] leading-[20.16px] capitalize">{account_name}</span>
          <span className="text-[15] leading-[20.16px]">
            {account_number} • {bank_name}
          </span>
        </div>
      </div>
      <Add size="24" color="#666666" className="cursor-pointer -rotate-45" onClick={onDelete(id)} />
    </div>
  );
};

export const EmptyBeneficiary = () => (
  <div className="flex flex-col gap-[40px] h-full items-center self-stretch">
    <img src={EmptyImg} className="md:w-[160px] md:h-[149px]" />
    <div className="flex flex-col gap-[10px]">
      <span className="font-medium text-xl leading-[27.72px] text-primary">No saved beneficiary</span>
      <span className="text-[#666666]">You don’t have any saved beneficiary yet. Your beneficiaries will appear here when saved.</span>
    </div>
  </div>
);

export const Beneficiary = ({ onClose }) => {
  const { register, watch } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();

  const beneficiaries = useSelector(state => state.account.get_beneficiaries.data);
  const loading = useSelector(state => state.account.get_beneficiaries.loading);

  const watch_search = watch("search"); // use this to filter

  const handleDelete = id => () => {
    onClose();
    dispatch(showDeleteModal("SHOW_DELETE_MODAL", id));
  };

  const handleSelect = item => () => {
    // alert(JSON.stringify(item));
    // dispatch(getAction(GET_BENEFICIARY_ID, `${beneficiaryURL}/${id}`, token));
    dispatch(setBeneficiaryData(item));
    onClose();
  };

  /** function to filter beneficiaries */
  const filterBeneficiaries = (search, beneficiaries) => {
    return !search ? beneficiaries : beneficiaries.filter(beneficiary => beneficiary.account_name?.toLowerCase().replace(/\s+/g, "").includes(search?.toLowerCase().replace(/\s+/g, "")));
  };

  const beneficiary_data = useMemo(() => beneficiaries.map(processBeneficiaries), [beneficiaries]);
  const filtered_beneficiaries = useMemo(() => filterBeneficiaries(watch_search, beneficiary_data), [watch_search, beneficiary_data]);

  const renderItem = ({ item, index }) => <BeneficiaryComp {...item} index={index} onDelete={handleDelete} onSelect={handleSelect(item)} />;

  return (
    <>
      {loading ? (
        <div className="p-[30px_51px_60px_51px]">
          <BeneficiarySkeleton />
        </div>
      ) : (
        <form className="p-[30px_51px_60px_51px] md:max-h-[645px] overflow-scroll flex flex-col gap-[30px]">
          <TextField type="text" {...register("search", { required: true })} placeholder="Search Beneficiary" className="border-[#F0F0F0]" />
          {beneficiary_data?.length < 1 ? <EmptyBeneficiary /> : <FlatList data={filtered_beneficiaries} renderItem={renderItem} />}
        </form>
      )}
    </>
  );
};
